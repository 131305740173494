const waterZoneList = [
    {
        "label":"Unzoned",
        "value":"Unzoned"
     },
     {
        "label":"Newcastle / Highfields",
        "value":"Newcastle / Highfields"
     },
     {
        "label":"South Wallsend",
        "value":"South Wallsend"
     },
     {
        "label":"Kooragang / Stockton",
        "value":"Kooragang / Stockton"
     },
     {
        "label":"Lookout",
        "value":"Lookout"
     },
     {
        "label":"Kurri / Cessnock",
        "value":"Kurri / Cessnock"
     },
     {
        "label":"Seaham / Hexham",
        "value":"Seaham / Hexham"
     },
     {
        "label":"Dungog Shire Council",
        "value":"Dungog Shire Council"
     },
     {
        "label":"Wyong",
        "value":"Wyong"
     }
]


const dataNetWorkChart = [
    { name: '00:00', value: 40, value2: 30 },
    { name: '02:00', value: 30, value2: 29 },
    { name: '04:00', value: 20, value2: 27 },
    { name: '06:00', value: 27, value2: 23 },
    { name: '08:00', value: 18, value2: 13 },
    { name: '10:00', value: 23, value2: 40 },
    { name: '12:00', value: 44, value2: 20 },
    { name: '14:00', value: 24, value2: 40 },
    { name: '16:00', value: 34, value2: 12 },
    { name: '18:00', value: 45, value2: 22 },
    { name: '20:00', value: 21, value2: 27 },
    { name: '22:00', value: 51, value2: 33 },
  ];

  const dataActivityChart = [
    { name: 'Jan', value: 40, value2: 30 },
    { name: 'Feb', value: 30, value2: 29 },
    { name: 'Mar', value: 20, value2: 27 },
    { name: 'Apr', value: 27, value2: 23 },
    { name: 'May', value: 18, value2: 13 },
    { name: 'Jun', value: 23, value2: 31 },
    { name: 'Jul', value: 34, value2: 22 },
  ];

  const dataRecentChart = [
    {
      title: "61 Main street",
      subTitle: "SpringField, QLD, 4754",
    },
    {
      title: "61 Main street",
      subTitle: "SpringField, QLD, 4754",
    },
    {
      title: "61 Main street",
      subTitle: "SpringField, QLD, 4754",
    }
  ]

  const dataCards = [
    {
      title: "Meters With Reading",
      subTitle: "Exceptions",
      value: 541,
      bgColor: "red",
    },
    {
      title: "Meters With Reading 2",
      subTitle: "Exceptions",
      value: 541,
      bgColor: "green",
    },
    {
      title: "Meters With Reading 3",
      subTitle: "Exceptions",
      value: 541,
      bgColor: "blue",
    },
    {
      title: "Meters With Reading 4",
      subTitle: "Exceptions",
      value: 541,
      bgColor: "orange",
    },
    {
      title: "Meters With Reading 5",
      subTitle: "Exceptions",
      value: 541,
      bgColor: "gray",
    }
  ]

  const usageAlerts = [
    {
      title: "Leak alerts",
      type: "leak_flow",
      value: 0,
    },
    {
      title: "No usage for >30 days",
      type: "no_usage",
      value: 0,
    },
    {
      title: "Bill Spike",
      type: "bill_spike",
      value: 0,
    },
    {
      title: "Peak Flow",
      type: "peak_flow",
      value: 0,
    },
    {
      title: "Step Change",
      type: "step_change",
      value: 0,
    },
    {
      title: "Night Flow",
      type: "night_flow",
      value: 0,
    }
  ]

  const deviceAlerts = [
    {
      title: "Poor Signal Strength",
      type: "poor_signal_strength",
      value: 0,
    },
    {
      title: "Non-communicating / reading exceptions",
      type: "non_communicating",
      value: 0,
    },
    {
      title: "Low battery",
      type: "low_battery",
      value: 0,
    },
    {
      title: "Tamper alert",
      type: "tamper_alert",
      value: 0,
    },
    {
      title: "Reverse flow",
      type: "reverse_flow",
      value: 0,
    },
    {
      title: "Zero flow",
      type: "zero_flow",
      value: 0,
    },
    {
      title: "Pressure abnormality",
      type: "pressure_abnormality",
      value: 0,
    }
  ]


  const dataNonRevenueChart = [
    { name: "Jan", percentage: 10, volume: 100 },
    { name: "Feb", percentage: 20, volume: 200 },
    { name: "Mar", percentage: 70, volume: 100 },
    { name: "Apr", percentage: 40, volume: 400 },
    { name: "Jun", percentage: 50, volume: 500 },
  ]

  const highUsageCustomers = [
    { name: "Vertex Global Solutions", usage: 32 },
    { name: "SynerTech Enterprises", usage: 24 },
    { name: "SummitWave Innovations", usage: 54 },
    { name: "Quantum Nexus Inc.", usage: 22 },
    { name: "Aegis Core Group", usage: 31 },
  ]

  const deviceStats = [
    { label: "Total meters", value: "2340" }, ///this {2340 = 2120 + 123 + 97} (Total meters = (Signal strength > -88 RSRP) + (Signal strength between -88 and –98 RSRP) + (Signal strength between –98 and -112 RSRP))
    { label: "Responsive meters", value: "97 %" },
    { label: "Data delivery", value: "97%" },
    { label: "Signal strength > -88 RSRP", value: 2120 },
    { label: "Signal strength between -88 and –98 RSRP", value: 123 },
    { label: "Signal strength between –98 and -112 RSRP", value: 97 },
    { label: "ECL Mode 2", value: 97 },
  ]

  const dataLineChart = [
    { name: 'Jan', SeriesA: 42, SeriesB: 2400, SeriesC: 2400 },
    { name: 'Feb', SeriesA: 66, SeriesB: 1398, SeriesC: 2210 },
    { name: 'Mar', SeriesA: 12, SeriesB: 9800, SeriesC: 2290 },
    { name: 'Apr', SeriesA: 52, SeriesB: 3908, SeriesC: 2000 },
    { name: 'May', SeriesA: 32, SeriesB: 4800, SeriesC: 2181 },
    { name: 'Jun', SeriesA: 80, SeriesB: 3800, SeriesC: 2500 },
    { name: 'Jul', SeriesA: 78, SeriesB: 4300, SeriesC: 2100 },
  ];

  const dataLineChart2 = [
    { name: 'Jan', SeriesA: 16, SeriesB: 2400, SeriesC: 2400 },
    { name: 'Feb', SeriesA: 60, SeriesB: 1398, SeriesC: 2210 },
    { name: 'Mar', SeriesA: 22, SeriesB: 9800, SeriesC: 2290 },
    { name: 'Apr', SeriesA: 44, SeriesB: 3908, SeriesC: 2000 },
    { name: 'May', SeriesA: 58, SeriesB: 4800, SeriesC: 2181 },
    { name: 'Jun', SeriesA: 23, SeriesB: 3800, SeriesC: 2500 },
    { name: 'Jul', SeriesA: 90, SeriesB: 4300, SeriesC: 2100 },
  ];

  const listDays = ['Last 7 days', 'Last month', 'Custom date range']
  // const listDays = [1, 2, 3, 4, 5, 6, 7]

export {
    waterZoneList,
    deviceAlerts,
    usageAlerts,
    dataCards,
    dataRecentChart,
    dataActivityChart,
    dataNetWorkChart,
    dataNonRevenueChart,
    highUsageCustomers,
    dataLineChart,
    dataLineChart2,
    deviceStats,
    listDays
}