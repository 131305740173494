import {
  isServerResponseError,
  hasError,
} from '../common'
import {
  pathOr,
  prop,
} from 'ramda'
import {
  getCustomerConsumptionQuarterlyUsage
} from '../../services/apis'

const getConsumptionQuarterlyData = async (id, from, to) => {
  const result = await getCustomerConsumptionQuarterlyUsage(id)({
    from,
    to
  })
  return isServerResponseError(result) || hasError(result)
    ? []
    : pathOr([], ['data', 'data'], result)
}

export default getConsumptionQuarterlyData
