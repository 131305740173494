import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { useHistory } from "react-router";
import { Button } from "react-bootstrap";
import update from "immutability-helper";

import FilterButton from "../../components/filter-button/filter-button";
import {
  getCustomersListData,
  getFilterOptions,
} from "../../utils/customer_utils";
import { PageHeading, Pager } from "../../components";
import CustomerItem from "./customer-item";
import { getApiUrl } from "../../utils/config-utility";
import { authService } from "../../../base/services";
import {
  propOr,
  isNil,
  isEmpty,
  andThen,
  values,
  equals,
  all,
  has,
  any,
  join,
  compose,
  map,
  prop,
  eqProps,
  findIndex,
  propEq,
  update as ramdaUpdate
} from "ramda";
import CustomersTableSkeleton from "./customers-table-skeleton";

import { track } from "src/main_app/mixpanel";
import { customerHasSumsData } from "src/main_app/services/apis";
import { formatNumber } from "src/main_app/utils/common";
import {
  useTenantConfig,
  useUserPermissions,
  checkPermission,
} from "src/main_app/actions";
import { v4 as uuidv4 } from 'uuid';

const CustomersPage = (props) => {
  useEffect(() => {
    track("Customer list page");
  }, []);

  const tenantConfig = useTenantConfig();

  const history = useHistory();
  const [total, setTotal] = useState(0);
  const [limit] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [customersList, setCustomersList] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);
  const [sectorOptions, setSectorOptions] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const isInitedRef = useRef(false);

  const filterButtonRef = useRef(null);

  const filterCount = useRef(0);
  const exportUrlRef = useRef(
    `${getApiUrl()}/export/customer/csv?&token=${authService.getAccessToken()}`
  );

  const userPermissions = useUserPermissions();
  const canViewThisPage = useMemo(() => checkPermission(userPermissions, "customers-page", "canView"), [userPermissions]) 
  useEffect(() => {
    if (!canViewThisPage) {
      alert("You don't have permission to view this page");
      document.location.href = "/";
    }
  }, [canViewThisPage]);

  const canExportCustomers = useMemo(
    () => checkPermission(userPermissions, "customers-page", "canExecute"),
    [userPermissions]
  );

  const getIndustryOptions = useCallback(() => {
    compose(andThen(setIndustryOptions), getFilterOptions)("Industry");
  }, [setIndustryOptions]);

  const getSearchData = (filterData, filterWith) => {
    let searchData = {};
    Object.keys(filterWith).forEach((filter) => {
      if (filterWith[filter]) {
        searchData[`search[${filter}]`] = filterData[filter];
      }
    });
    return searchData;
  };

  const updateFilterCount = useCallback((filterData, filterWith) => {
    let count = 0;
    Object.keys(filterWith).forEach((filter) => {
      if (filterWith[filter] === true) {
        count++;
      }
    });
    filterCount.current = count;
  }, []);

  const getSectorOptions = useCallback(
    () => compose(setSectorOptions, getFilterOptions)("Type"),
    [setSectorOptions]
  );

  const goToOverview = useCallback((customer) => {
    const defaultPageDetail = tenantConfig.slug === "intellihub" ? "usage" : "overview";
    history.push(`/customer/${customer.id}/${defaultPageDetail}`);
  }, []);

  const onPageChange = async (pageNo, filterData = null, filterWith = null) => {
    filterData ??= filterButtonRef.current.getFilteredData();
    filterWith ??= filterButtonRef.current.getFilterWith();
    const { total, data } = await getCustomersListData(
      pageNo,
      limit,
      null,
      null,
      getSearchData(filterData, filterWith),
      {
        "selected_fields[install_number]": true,
      }
    );
    setCurrentPage(pageNo);
    setTotal(total);
    setCustomersList(data);
  };

  const checkCustomerHasSumsData = useCallback(async () => {
    const checked = any(has("has_sums_data"), customersList);
    if (!checked) {
      const customer_ids = compose(join(","), map(prop("id")))(customersList);
      if (isEmpty(customer_ids)) return;
      const res = await customerHasSumsData(customer_ids);
      const data = propOr([], "data", res.data);
      const trade_waste = propOr([], "trade_waste", res.data);
      const updateCmd = {};

      let newCustomersList = customersList;
      for (const item of data) {
        // update customersList by itemId
        const customerIndex = findIndex(propEq('id', item.id))(customersList);

        // update to add {has_sums_data: <bool>} to the customer
        newCustomersList = ramdaUpdate(customerIndex, {
          ...customersList[customerIndex],
          ...item
        }, newCustomersList);
      }

      let customers = newCustomersList
      for (const item_trade_waste of trade_waste) {
        const customerIndexTradeWaste = findIndex(propEq('id', item_trade_waste.id))(newCustomersList);
        customers = ramdaUpdate(customerIndexTradeWaste, {
          ...newCustomersList[customerIndexTradeWaste],
          ...item_trade_waste
        }, customers);
      }
      setCustomersList(customers);
    }
  }, [customersList]);

  useEffect(() => {
    checkCustomerHasSumsData();
  }, [customersList]);

  const updateExportUrl = (filterWith, filterData) => {
    exportUrlRef.current = `${getApiUrl()}/export/customer/csv?&token=${authService.getAccessToken()}`;
    Object.keys(filterWith).forEach((filter) => {
      if (filterWith[filter]) {
        exportUrlRef.current += `&search[${filter}]=${filterData[filter]}`;
      }
    });
  };

  const startLoad = useCallback(() => {
    if (isLoaded) setIsLoaded(false);
  }, [isLoaded, setIsLoaded]);

  const onLoaded = useCallback(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 666);
  }, [isLoaded, setIsLoaded]);

  const onFilterApply = () => {
    try {
      if (!isInitedRef.current) {
        isInitedRef.current = true;
        return;
      }

      const filterButton = filterButtonRef.current;
      if (isNil(filterButton)) {
        startLoad();
        onPageChange(1).then(onLoaded);
        return;
      }

      const filterData = filterButton.getFilteredData();
      const filterWith = filterButton.getFilterWith();
      if (
        (isEmpty(filterData) && isEmpty(filterWith)) ||
        all(equals(false), values(filterWith))
      ) {
        startLoad();
        onPageChange(1).then(onLoaded);
        filterCount.current = 0;
        return;
      }

      startLoad();
      onPageChange(1, filterData, filterWith).then(onLoaded);
      updateFilterCount(filterData, filterWith);
      updateExportUrl(filterWith, filterData);
    } catch (error) {
      console.log("error", error);
    }
  };

  const user = authService.getUser();

  useEffect(() => {
    getIndustryOptions();
    getSectorOptions();
  }, []);

  return (userPermissions.length === 0 || !canViewThisPage) ? null : (
    <div className="the-content">
      {user.role != "Customer" && <PageHeading title="Customers" /> }
      {user.role != "Customer" &&
        <div className="container-fluid content">
          <div className="action-buttons block-center-between">
            <div className="block-center">
              <FilterButton
                ref={filterButtonRef}
                industryOptions={industryOptions}
                sectorOptions={sectorOptions}
                onFiltersApply={onFilterApply}
                filterCount={filterCount.current}
              />
            </div>
            <div>
              {total >= 50 && (
                <span className="mr-3">
                  Showing {limit} of {formatNumber(total)} customers
                </span>
              )}
              {total < 50 && (
                <span className="mr-3">
                  {formatNumber(total)} customers
                </span>
              )}
              {canExportCustomers && (
                <Button
                  disabled={!isLoaded}
                  className="btn btn-default"
                  href={exportUrlRef.current}
                >
                  Export
                </Button>
              )}
            </div>
          </div>
          {!isLoaded ? (
            <CustomersTableSkeleton tenantConfig={tenantConfig} line={limit} />
          ) : (
            <>
              <div className="table-responsive">
                <table className="table customer-list">
                  <thead>
                    <tr>
                      <>
                        {(tenantConfig.customerFieldsHeading || []).map(
                          columnName => <th key={uuidv4()} scope="col">{columnName}</th>
                        )}
                      </>
                    </tr>
                  </thead>
                  <tbody>
                    {customersList.map((customer, index) => (
                      <CustomerItem
                        tenantConfig={tenantConfig}
                        customer={customer}
                        key={index + ""}
                        onClick={goToOverview}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-lg-end pagination-box">
                <Pager
                  onPageChange={onPageChange}
                  total={total}
                  limit={limit}
                  currentPage={currentPage}
                />
              </div>
            </>
          )}
        </div>
      }
    </div>
  );
};

export default CustomersPage;
