import { FlyToInterpolator, Marker, WebMercatorViewport } from "react-map-gl";
import React from "react";
import { andThen, compose, max, min, path } from "ramda";

import { getCustomerData } from "../../utils/customer_utils";
import { layerNames } from "./layers/layer-config";

const processClusterInfo = async (cluster) => {
  const { properties } = cluster;
  if (!properties) {
    return null;
  }
  switch (properties.type) {
    case layerNames.customers:
      const data = await getCustomerData(properties.id);
      return {
        ...data,
        type: properties.type,
      };
    case layerNames.poor_signal_strength:
      const dataPoorSignal = await getCustomerData(properties.id);
      return {
        ...dataPoorSignal,
        type: 'customers',
      };
    case layerNames.bom:
    case layerNames.wasteWater:
    case layerNames.dams:
      return properties;
  }
  return {};
};

const MapCluster = ({
  type,
  cluster,
  superCluster,
  viewport,
  setViewport,
  onMouseIn,
  onMouseOut,
  onClick,
}) => {
  const [longitude, latitude] = cluster.geometry.coordinates;
  const {
    cluster: isCluster,
    point_count: pointCount,
    name,
  } = cluster.properties;

  const getBoundViewPort = (points) => {
    let topLeftLat = 1000;
    let topLeftLong = -1000;
    let bottomRightLat = -1000;
    let bottomRightLong = 1000;
    points.forEach((point) => {
      const coordinates = path(["geometry", "coordinates"], point);
      topLeftLat = min(topLeftLat, coordinates[1]);
      topLeftLong = max(topLeftLong, coordinates[0]);
      bottomRightLat = max(bottomRightLat, coordinates[1]);
      bottomRightLong = min(bottomRightLong, coordinates[0]);
    });
    return [
      [topLeftLong, topLeftLat],
      [bottomRightLong, bottomRightLat],
    ];
  };

  const fitClusterBound = (points) => {
    const { longitude, latitude, zoom } = new WebMercatorViewport(
      viewport
    ).fitBounds(getBoundViewPort(points));

    setViewport({
      ...viewport,
      longitude,
      latitude,
      zoom: zoom - 1,
      transitionInterpolator: new FlyToInterpolator({
        speed: 2,
      }),
      transitionDuration: "auto",
    });
  };

  const onMouseEnter = () => onMouseIn({ name, type, latitude, longitude });

  if (isCluster) {
    if (viewport.maxZoom === viewport.zoom && pointCount > 1) {
      const clusters = superCluster.getChildren(cluster.id);

      const getLatLon = i => ({
        latitude: latitude + (8 * i) / 1000000,
        longitude: longitude + (8 * i) / 1000000,
      });

      return (
        <>
          {clusters.map((c, i) => {
            const latLon = getLatLon(i);
            return (
              <Marker key={i + ""} {...latLon}>
                <span
                  className={`map-marker ${type}`}
                  onMouseEnter={() =>
                    onMouseIn({ name: c.properties.name, type, ...latLon })
                  }
                  onMouseLeave={onMouseOut}
                  onClick={(event) => {
                    setViewport({
                      ...viewport,
                      transitionInterpolator: new FlyToInterpolator({
                        speed: 2,
                      }),
                      transitionDuration: "auto",
                      ...latLon,
                    });
                    compose(
                      andThen(
                        _ => onClick({
                          ..._,
                          ...latLon
                        })
                      ), 
                      processClusterInfo
                    )(c);
                  }}
                  style={{ transform: `translate(-50%, -50%` }}
                />
              </Marker>
            );
          })}
        </>
      );
    } else {
      const onMarkerClick = () => {
        fitClusterBound(superCluster.getLeaves(cluster.id, Infinity, 0));
      };

      return (
        <Marker latitude={latitude} longitude={longitude}>
          <div className={`map-marker ${type} cluster`} onClick={onMarkerClick}>
            {pointCount}
          </div>
        </Marker>
      );
    }
  }

  return (
    <Marker latitude={latitude} longitude={longitude}>
      <span
        className={`map-marker ${type}`}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseOut}
        onClick={(event) => {
          setViewport({
            ...viewport,
            longitude,
            latitude,
            transitionInterpolator: new FlyToInterpolator({
              speed: 2,
            }),
            transitionDuration: "auto",
          });
          compose(
            andThen(onClick),
            processClusterInfo
          )(cluster);
        }}
        style={{ transform: `translate(-50%, -50%` }}
      />
    </Marker>
  );
};

export default MapCluster;
