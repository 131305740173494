import { __, compose, map, prop, propOr } from "ramda";
import React, { useLayoutEffect, useState, useRef } from "react";
import { createPopper } from "@popperjs/core";
import { isNilOrEmpty, toString } from "src/main_app/utils/common";
import { getCustomersListData } from "src/main_app/utils/customer_utils";

import AutoComplete from "../auto_complete";

const Search = (props) => {
  const [hasData, setHasData] = useState(false);
  const waitToCreateTooltipRef = useRef()
  const renderResult = (item) => {
    console.log('item search', item)
    if (!item.id) return item.text;
    return $(`
        <span data-item-id="${item.id}" data-type="customer" class="name-text">${item.text ? item.text : item.address_name}</span>
        <a class="link-map">
          <div class="icon-type">
            <img popper-title="Customer detail" popper="top" data-item-id="${item.id}" data-type="customer" src="/assets/images/icon-user.png" width="30"/>
          </div>
        </a>
        <a class="link-map">
          <div class="icon-type">
            <img popper-title="Map" popper="top" data-item-id="${item.id}" data-type="map" src="/assets/images/location.png" width="30"/>
          </div>
        </a>
        <a class="link-map">
          <div class="icon-type">
            <img popper-title="Alert" popper="top" data-item-id="${item.id}" data-type="alert" src="/assets/images/bell-global-search.png" width="30" style="border-radius: 5px"/>
          </div>
        </a>
    `);
  };

  const createToolTip = (target) => {
    const title = target.getAttribute("popper-title");
    const placement = target.getAttribute("popper");

    const popperContainer = document.createElement("div");
    popperContainer.classList.add("popper-container");
    popperContainer.innerHTML = `<div class="popper-tooltip" role="tooltip">${title}    
          <div class="popper-arrow" data-popper-arrow></div>
        </div>`;
    target.parentElement.appendChild(popperContainer);
    createPopper(target, popperContainer, {
      placement,
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 5],
          },
        },
      ],
    });
  };

  useLayoutEffect(() => {
    if (!hasData || waitToCreateTooltipRef.current) return;
    const displayTooltip = (event) => {
      const { target } = event;
      if (!target.hasAttribute("popper")) return;

      clearTimeout(waitToCreateTooltipRef.current);
      waitToCreateTooltipRef.current = setTimeout(() => {
        createToolTip(target);
      }, 1000);

      const onMouseOut = () => {
        clearTimeout(waitToCreateTooltipRef.current);
        target.removeEventListener("mouseout", onMouseOut);
        const tooltipContainer =
          target.parentElement.querySelector(".popper-container");
        tooltipContainer && tooltipContainer.remove();
      };
      target.addEventListener("mouseout", onMouseOut);
    };

    document.addEventListener("mouseover", displayTooltip);
    return () => {
      clearTimeout(waitToCreateTooltipRef.current);
      waitToCreateTooltipRef.current = undefined
      document.removeEventListener("mouseover", displayTooltip);
    };
  }, [hasData]);

  const onSearch = (params, startQuery, success, failure) => {
    setHasData(false);
    startQuery && startQuery();
    getCustomersListData(1, 10, undefined, undefined, {
      "search[name]": params.data.term,
    }).then((data) => {
      console.log('dataa sêarch', data)
      const results = compose(
        map((item) => ({
          id: item.id,
          text: item.name,
          owner_name: item.owner_name,
          trading_name: item.trading_name,
          site_name: item.site_name,
          address_name: item.address +', Installation ID '+ item.uuid
        })),
        prop("data")
      )(data);
      success({
        results,
      });
      setHasData(true);
    });
  };

  const onClickItem = (event, items) => {
    const customer = items.find(
      (item) => item.id + "" === event.target.dataset.itemId
    );

    switch (event.target.dataset["type"]) {
      case "customer":
        document.location.href = `/customer/${customer.id}/overview`;
        break;
      case "map":
        const name = !isNilOrEmpty(customer.trading_name)
          ? customer.trading_name
          : !isNilOrEmpty(customer.owner_name)
          ? customer.owner_name
          : propOr("", "site_name", customer);
        sessionStorage.setItem("customerId", customer.id);
        sessionStorage.setItem("name", name);
        setTimeout(() => {
          document.location.href = "/map";
        }, 0);
        break;
      case "alert":
        document.location.href = `/customer/${customer.id}/alerts`;
        break;
    }
  };

  return (
    <div className="search-bar">
      <AutoComplete
        className="form-group mb-0"
        placeholder="Search for customer or business"
        onSearch={onSearch}
        renderResult={renderResult}
        onClickItem={onClickItem}
      />
    </div>
  );
};

export default Search;
