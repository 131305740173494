// layouts
import { DefaultLayout } from "../layouts";

import HomePage from "../scenes/home-page";
import ReportPage from "../scenes/report-page";
import ReportSinglePage from "../scenes/report-single-page";
import ReportsListPage from "../scenes/reports-list-page";
import MapPage from "../scenes/map-page";
import PlansPage from "../scenes/plans-page";
import CustomersPage from "../scenes/customers_page";

import CustomerDetailPage from "../scenes/customer_detail_page";
import CustomerOverviewPage from "../scenes/customer_detail_page/tabs/customer_overview_page";
import CustomerTimelinePage from "../scenes/customer_detail_page/tabs/customer_timeline_page";
import CustomerActionsPage from "../scenes/customer_detail_page/tabs/customer_actions_page";
import CustomersUsagePage from "../scenes/customer_detail_page/tabs/customers_usage_page";
import CustomerAlertsPage from "../scenes/customer_detail_page/tabs/customer_alerts_page";

import IndustryPage from "../scenes/industry-page/industry-page";
import IndustryDetailPage from "../scenes/industry_detail_page";
import AlertsPage from "../scenes/alerts_page/alerts-page";

import TestPage from "../scenes/test";

import { UserAddPage, UserEditPage, UsersPage } from "../scenes/user";
import { RolesPage } from "../scenes/roles_page";
import ReportPageExport from "../scenes/report";
import DataAlertImport from "../scenes/import_page";
import ImportDetailPage from "../scenes/import_detail_page";
import SettingsPage from "../scenes/settings_page";
import SettingReceiveMailPage from "../scenes/setting_receive_mail_page";

import NotificationsPage from "../scenes/setting_receive_mail_page/tab/notifications-page";
import RealTimeWaterUsage from "../scenes/insighs-page/tab/real-time-water-usage-page";
import MeteredWaterUsage from "../scenes/insighs-page/tab/metered-water-usage-page";
import DataValidation from "../scenes/insighs-page/tab/data-validation-page";
import WaterBalancePage from "../scenes/insighs-page/tab/water-balance-page";
import TvAppPage from "../scenes/insighs-page/tab/tv-app-page";
import InsightsPage from "../scenes/insighs-page";
import NotesPage from "../scenes/notes-page";

import ProvideFeedbackPage from "../scenes/setting_receive_mail_page/tab/provide-feedback-page";
import ContactAddressPage from "../scenes/setting_receive_mail_page/tab/contact-address-page";

import DashBoardPage from "../scenes/dashboard_page";

const normalUserRoutes = [
  {
    exact: true,
    path: "/test",
    component: TestPage,
    layout: DefaultLayout,
  },
  {
    exact: true,
    path: "/",
    requireLogin: true,
    component: HomePage,
    layout: DefaultLayout,
  },
  {
    exact: true,
    path: "/map",
    requireLogin: true,
    component: MapPage,
    layout: DefaultLayout,
  },
  {
    exact: true,
    path: "/dashboard",
    requireLogin: true,
    component: DashBoardPage,
    layout: DefaultLayout,
  },
  {
    path: "/insights",
    requireLogin: true,
    component: InsightsPage,
    layout: DefaultLayout,
    routes: [
      // {
      //   exact: true,
      //   path: "/real-time-water-usage",
      //   requireLogin: true,
      //   component: RealTimeWaterUsage,
      //   layout: DefaultLayout,
      // },
      // {
      //   exact: true,
      //   path: "/metered-water-usage",
      //   requireLogin: true,
      //   component: MeteredWaterUsage,
      //   layout: DefaultLayout,
      // },
      {
        exact: true,
        path: "/data-validation",
        requireLogin: true,
        component: DataValidation,
        layout: DefaultLayout,
      },
      // {
      //   exact: true,
      //   path: "/water-balance",
      //   requireLogin: true,
      //   component: WaterBalancePage,
      //   layout: DefaultLayout,
      // },
      // {
      //   exact: true,
      //   path: "/tv-app",
      //   requireLogin: true,
      //   component: TvAppPage,
      //   layout: DefaultLayout,
      // },
    ],
  },
  // {
  //   exact: true,
  //   path: "/real-time-water-usage1",
  //   requireLogin: true,
  //   component: RealTimeWaterUsage,
  //   layout: DefaultLayout,
  // },
  {
    exact: true,
    path: "/report",
    requireLogin: true,
    component: ReportPageExport,
    layout: DefaultLayout,
  },
  {
    exact: true,
    path: "/reports",
    requireLogin: true,
    component: ReportsListPage,
    layout: DefaultLayout,
  },
  {
    exact: true,
    path: "/reports/:action",
    requireLogin: true,
    component: ReportsListPage,
    layout: DefaultLayout,
  },
  {
    exact: true,
    path: "/report-details/:id",
    requireLogin: true,
    component: ReportSinglePage,
    layout: DefaultLayout,
  },
  {
    exact: true,
    path: "/report-details/:id/:action",
    requireLogin: true,
    component: ReportSinglePage,
    layout: DefaultLayout,
  },
  {
    exact: true,
    path: "/insights/single",
    requireLogin: true,
    component: ReportSinglePage,
    layout: DefaultLayout,
  },
  {
    exact: true,
    path: "/plan",
    requireLogin: true,
    component: PlansPage,
    layout: DefaultLayout,
  },
  {
    exact: true,
    path: "/customers",
    requireLogin: true,
    component: CustomersPage,
    layout: DefaultLayout,
  },
  {
    path: "/customer/:id",
    requireLogin: true,
    component: CustomerDetailPage,
    layout: DefaultLayout,
    routes: [
      {
        exact: true,
        path: "/overview",
        requireLogin: true,
        component: CustomerOverviewPage,
        layout: DefaultLayout,
      },
      {
        exact: true,
        path: "/usage",
        requireLogin: true,
        component: CustomersUsagePage,
        layout: DefaultLayout,
      },
      {
        exact: true,
        path: "/actions",
        requireLogin: true,
        component: CustomerActionsPage,
        layout: DefaultLayout,
      },
      {
        exact: true,
        path: "/timeline",
        requireLogin: true,
        component: CustomerTimelinePage,
        layout: DefaultLayout,
      },
      {
        exact: true,
        path: "/alerts",
        requireLogin: true,
        component: CustomerAlertsPage,
        layout: DefaultLayout,
      },
    ],
  },
  {
    exact: true,
    path: "/industry",
    requireLogin: true,
    component: IndustryPage,
    layout: DefaultLayout,
  },
  {
    exact: true,
    path: "/industry/:id",
    requireLogin: true,
    component: IndustryDetailPage,
    layout: DefaultLayout,
  },
  {
    exact: true,
    path: "/alerts",
    requireLogin: true,
    component: AlertsPage,
    layout: DefaultLayout,
  },
  {
    exact: true,
    path: "/notes",
    requireLogin: true,
    component: NotesPage,
    layout: DefaultLayout,
  },
  // {
  //   exact: true,
  //   path: "/roles",
  //   requireLogin: true,
  //   component: RolesPage,
  //   layout: DefaultLayout,
  // },
  {
    exact: true,
    path: "/users",
    requireLogin: true,
    component: UsersPage,
    layout: DefaultLayout,
  },
  {
    exact: true,
    path: "/user/add",
    requireLogin: false,
    component: UserAddPage,
    layout: DefaultLayout,
  },
  {
    exact: true,
    path: "/user/:id/edit",
    requireLogin: true,
    component: UserEditPage,
    layout: DefaultLayout,
  },
  {
    exact: true,
    path: "/import-dashboard",
    requireLogin: true,
    component: DataAlertImport,
    layout: DefaultLayout,
  },
  {
    exact: true,
    path: "/alert-import/:id",
    requireLogin: true,
    component: ImportDetailPage,
    layout: DefaultLayout,
  },
  {
    path: "/settings",
    requireLogin: true,
    component: SettingReceiveMailPage,
    layout: DefaultLayout,
    routes: [
      {
        exact: true,
        path: "/notifications",
        requireLogin: true,
        component: NotificationsPage,
        layout: DefaultLayout,
      },
      {
        exact: true,
        path: "/provide-feedback",
        requireLogin: true,
        component: ProvideFeedbackPage,
        layout: DefaultLayout,
      },
      {
        exact: true,
        path: "/contact-address",
        requireLogin: true,
        component: ContactAddressPage,
        layout: DefaultLayout,
      },
      {
        exact: true,
        path: "/roles",
        requireLogin: true,
        component: RolesPage,
        layout: DefaultLayout,
      },
    ],
  },
];

export default normalUserRoutes;
