import React, { useCallback, useMemo, useEffect, useRef } from "react";
import moment from "moment";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
  Legend,
} from "recharts";
import { flatten, isNil, values, reject } from "ramda";
import { DateRangePicker } from "src/main_app/components/date_range_picker";
import {
  formatNumber,
  isNilOrEmpty,
  randomColor,
  isNotEmpty,
} from "src/main_app/utils/common";
import { authService } from "../../../../../base/services";
import { saveAs } from 'file-saver'; 
import { useCurrentPng } from "recharts-to-png";
import { useTenantConfig } from "src/main_app/actions/tenant";
import * as htmlToImage from 'html-to-image';

const tooltipContentStyle = {
  boxShadow: "0 10px 24px rgba(29,42,68,0.12)",
  borderRadius: "4px",
  border: "none",
  textAlign: "center",
};

const tooltipItemStyle = {
  color: "#05d6f",
  fontSize: "12px",
  fontWeight: "500",
};

const yFormatter = (tickItem) => formatNumber(tickItem) + " ";

const ticksFormatter = (value, index) =>
  moment(value * 1000).format("ddd MMM DD");

const renderTooltip = ({ active, payload, label }) => {
  if (!active || !payload || !payload.length) return null;
  return (
    <div
      className="custom-line-tooltip"
      style={{
        padding: "5px",
        backgroundColor: "#fff",
        boxShadow: "0 0 8px 8px rgb(0 0 0 / 7%)",
        borderRadius: "4px",
      }}
    >
      <h5 className="mb-0">{payload[0].payload.displayDate}</h5>
      <br />
      {payload.map((payloadItem, index) => (
        <div
          key={index + ""}
          style={{
            color: payloadItem.stroke,
          }}
        >
          {payloadItem.name}: {formatNumber(payloadItem.value)}{" "}
          {payloadItem.unit}
        </div>
      ))}
    </div>
  );
};

const renderTooltip2 = ({ active, payload, label }) => {
  if (!active || !payload || !payload.length) return null;

  return (
    <div
      className="custom-line-tooltip"
      style={{
        backgroundColor: "#fff",
        minWidth: "260px",
        boxShadow: "0 0 8px 8px rgb(0 0 0 / 5%)",
        overflow: 'auto',
        maxHeight:'410px',
        position:'relative',
        pointerEvents: 'auto'
      }}
    >
    <div style={{padding:"20px 20px 0px 20px"}}>
      <p style={{fontWeight: "bold", marginBottom: "-2px"}}>{payload[0].payload.startDate} - {payload[0].payload.date}</p>
      <p style={{color: "#727f94"}}>Total usage: {formatNumber(payload[0].payload.usage)} kL</p>
    </div>
      <hr></hr>
      <div style={{paddingBottom: "15px"}}>
        <table style={{fontSize: "13px"}}>
          <tr>
            <th style={{padding: "5px 0px 5px 20px"}}>SERIAL</th>
            <th>USAGE</th>
          </tr>
          {payload[0].payload.merterSerials.map((item, index) => (
            <tr key={index + ""}>
              <td style={{padding:" 5px 0 5px 20px", color: "#727f94"}}>{item}</td>
              <td style={{color: "#727f94"}}> {payload[0].payload.consumptions[index] >= 0 ? formatNumber(payload[0].payload.consumptions[index]) : 0} kL</td>
            </tr>
          ))}
        </table>
      </div>
    </div>
  );
};

const renderTooltipQuarterlyUsage = ({ active, payload, label }) => {
  if (!active || !payload || !payload.length) return null;

  return (
    <div
      className="custom-line-tooltip"
      style={{
        backgroundColor: "#fff",
        minWidth: "260px",
        boxShadow: "0 0 8px 8px rgb(0 0 0 / 5%)",
        overflow: 'auto',
        maxHeight:'410px',
        position:'relative',
        pointerEvents: 'auto'
      }}
    >
      <div style={{padding:"20px 20px 0px 20px"}}>
        <p style={{fontWeight: "bold", marginBottom: "-2px"}}>{payload[0].payload.startDate}</p>
        <p style={{color: "#727f94",  marginBottom: "-2px"}}>Meter id: {payload[0].payload.merterSerials[0]}</p>
        <p style={{color: "#727f94"}}>Total usage: {formatNumber(payload[0].payload.usage)} kL</p>
      </div>
    </div>
  );
};

const CustomersUsagePage = ({
  hasRealtimeData,
  dateRangeDataWeekly,
  getDataWeekly,
  dataWeekly,
  meterNames,

  dateRangeWaterUsage,
  dateRangeBilling,
  getUsageData,
  usageData,
  getBillingData,
  billingData,
  handleDownloadDataRealTime,
  ticks,

  dateRangeQuarterly,
  getQuarterlyData,
  dataQuarterly,
  meterNamesQuarterly,
  customer
}) => {

  const tenantConfig = useTenantConfig();
  const checkHasRealtimeData = [];
  if (hasRealtimeData) {
    for (const itemDataWeekly of dataWeekly) {
      if (itemDataWeekly['date'] != undefined) {
        checkHasRealtimeData.push(itemDataWeekly);
        break;
      }
    }
  }
  
  const allSumData = useMemo(() => {
    const dataUsage = [];
    if (!hasRealtimeData) return dataUsage;
    
    for (const itemDataWeekly of dataWeekly) {
      const { date, displayDate, ...dataSumUsage } = itemDataWeekly;
      dataUsage.push(values(dataSumUsage));
    }
    return reject(isNil, flatten(dataUsage));
  }, [dataWeekly, hasRealtimeData]);

  const dataWeeklyMax = useMemo(
    () => Math.ceil(Math.max(...allSumData) * 10) / 10,
    [allSumData]
  );
  

  const allDataQuarterly = useMemo(() => {
    const dataUsage = [];
    
    for (const itemDataQuarterly of dataQuarterly) {
      const { date, displayDate, ...dataSumUsage } = itemDataQuarterly;
      dataUsage.push(values(dataSumUsage));
    }
    return reject(isNil, flatten(dataUsage));
  }, [dataQuarterly, hasRealtimeData]);

  const dataQuarterlyMax = useMemo(
    () => Math.ceil(Math.max(...allDataQuarterly) * 10) / 10,
    [allDataQuarterly]
  );


  const onApplyWeeklyConsumption = useCallback(
    (event, picker) => {
      getDataWeekly &&
        getDataWeekly(
          picker.startDate.utcOffset(0, true).toDate(),
          picker.endDate.utcOffset(0, true).toDate()
        );
    },
    [getDataWeekly]
  );

  const onApplyWaterUsage = useCallback(
    (event, picker) => {
      getUsageData &&
        getUsageData(
          picker.startDate.utcOffset(0, true).toDate(),
          picker.endDate.utcOffset(0, true).toDate()
        );
    },
    [getUsageData]
  );

  const onApplyBillingUsage = useCallback(
    (event, picker) => {
      getBillingData &&
        getBillingData(
          picker.startDate.utcOffset(0, true).toDate(),
          picker.endDate.utcOffset(0, true).toDate()
        );
    },
    [getBillingData]
  );

  const onApplyQuarterlyUsage = useCallback(
    (event, picker) => {
      getQuarterlyData &&
        getQuarterlyData(
          picker.startDate.utcOffset(0, true).toDate(),
          picker.endDate.utcOffset(0, true).toDate()
        );
    },
    [getQuarterlyData]
  );

  const yLabel = useMemo(
    () => ({
      value: "Usage (kL)",
      angle: -90,
      position: "top",
      dy: 170,
      dx: -15,
    }),
    []
  );

  const yLabelSUMs = useMemo(
    () => ({
      value: "L/s",
      angle: -90,
      position: "top",
      dy: 170,
      dx: -10,
    }),
    []
  );

  const user = authService.getUser();
  
  useEffect(() => {
    import("./style.scss");
  }, []);

//Donwload image chart
// //Real-time chart
// const [getRealTimeChartPng, { ref: realTimeChartRef, isLoading}] = useCurrentPng();
// const handleRealTimeDownload = useCallback(async () => {
//   const png = await getRealTimeChartPng();
//   if (png) {
//     saveAs(png, "real-time-weekly-consumption-chart.png");
//   }
// }, [getRealTimeChartPng]);

//Real-time chart
const handleRealTimeDownload = () =>{
  htmlToImage.toPng(document.getElementById("realtime-chart"))
   .then(function (dataUrl) {
     saveAs(dataUrl, 'real-time-weekly-consumption-chart.png');
   });
 }
//Usage chart
 const handleMeteredDownload = () =>{
  htmlToImage.toPng(document.getElementById("metered-chart"))
   .then(function (dataUrl) {
     saveAs(dataUrl, 'metered-consumption-chart.png');
   });
 }
//Billing chart
const handleBillingDownload = () =>{
  htmlToImage.toPng(document.getElementById("billing-chart"))
   .then(function (dataUrl) {
     saveAs(dataUrl, 'billing-consumption-chart.png');
   });
 }

const handleQuarterlyDownload = () =>{
  htmlToImage.toPng(document.getElementById("quarterly-usage-chart"))
   .then(function (dataUrl) {
     saveAs(dataUrl, 'quarterly-usage-chart.png');
   });
 }

  return (
    <>
      { hasRealtimeData && (
        <div className="container-fluid content">
          <h3 className="sub-title">{tenantConfig?.isWeeklyUsageTitle ? 'Weekly Usage' : 'Weekly Consumption'}</h3>
          <div className="form-group">
            <label>Date range</label>
            <DateRangePicker
              setting={{
                startDate: dateRangeDataWeekly[0],
                endDate: dateRangeDataWeekly[1],
              }}
              onApply={onApplyWeeklyConsumption}
            >
              <input type="text" className="form-control" />
            </DateRangePicker>
          </div>
          {/* { isNilOrEmpty(dataWeekly) ? ( */}
          { isNilOrEmpty(checkHasRealtimeData) ? (
            <div className="no-item-message">
              {" "}
              There is currently no usage data available.
            </div>
          ) : (
            <>
            <div>
              <a className="btn btn-primary btn-sm" onClick={handleRealTimeDownload}>Download as image</a>
              <a className="btn btn-primary btn-sm ml-2" onClick={handleDownloadDataRealTime}>Download data</a>
            </div>
            <div className="card" id="realtime-chart">
              <div className="card-body">
                <ResponsiveContainer width="100%" aspect={10.0 / 3.0}>
                    <LineChart 
                    height={180}
                    data={dataWeekly}
                    margin={{
                      top: 20,
                      right: 5,
                      left: -15,
                      bottom: 5,
                    }}
                    barGap={5}
                    barCategoryGap={5}
                  >
                    <CartesianGrid stroke="#e9ebf1" />
                    <XAxis
                      dataKey="date"
                      stroke="#e9ebf1"
                      type={"category"}
                      interval={"preserveStartEnd"}
                      ticks={ticks}
                      tickFormatter={ticksFormatter}
                    />
                    <YAxis
                      stroke="#e9ebf1"
                      domain={[0, dataWeeklyMax]}
                      label={yLabelSUMs}
                    />
                    {meterNames.map((meterName, index) => (
                      <Line
                        key={index + ""}
                        type="monotone"
                        dataKey={meterName}
                        name={tenantConfig.isConcatMeterAddress ? meterName + " - " + customer.address + `, ${customer.suburb_name}` : meterName}
                        stroke={index === 0 ? "#09A3B3" : randomColor(index)}
                        dot={false}
                        unit={" L/s"}
                      />
                    ))}
                    <Tooltip
                      contentStyle={tooltipContentStyle}
                      itemStyle={tooltipItemStyle}
                      content={renderTooltip}
                    />
                    <Legend iconType={"circle"} margin={{ top: 50 }} />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
            </>
          )}
        </div>
      )}
      {!tenantConfig.isDisabledMeteredChart && (
        <>
         {user.role != "Customer" &&
          <div className="container-fluid content">
            <h3 className="sub-title">Metered Consumption</h3>
            <div className="form-group">
              <label>Date range</label>
              <DateRangePicker
                setting={{
                  startDate: dateRangeWaterUsage[0],
                  endDate: dateRangeWaterUsage[1],
                }}
                onApply={onApplyWaterUsage}
              >
                <input type="text" className="form-control" />
              </DateRangePicker>
            </div>
            {isNilOrEmpty(usageData) ? (
              <div className="no-item-message">
                {" "}
                There is currently no usage data available.
              </div>
            ) : (
              <>
              <a className="btn btn-primary btn-sm" onClick={handleMeteredDownload}>Download as image</a>
              <div className="card" id="metered-chart">
                <div className="card-body">
                  <ResponsiveContainer width="100%" aspect={10.0 / 3.0}>
                    <BarChart
                      data={usageData}
                      margin={{
                        top: 20,
                        right: 5,
                        left: -15,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid stroke="#e9ebf1" />
                      <XAxis dataKey="date" stroke="#e9ebf1" type={"category"} />
                      <YAxis
                        dataKey={"usage"}
                        stroke="#e9ebf1"
                        tickFormatter={yFormatter}
                        label={yLabel}
                      />
                      <Tooltip
                        contentStyle={{
                          boxShadow: "0 10px 24px rgba(29,42,68,0.12)",
                          borderRadius: "4px",
                          border: "none",
                          pointerEvents: 'auto'
                        }}
                        itemStyle={{
                          color: "#05d6f",
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        content={renderTooltip2}
                      />
                      <Bar
                        dataKey={"usage"}
                        fill="#09A3B3"
                        radius={[5, 5, 0, 0]}
                        barSize={24}
                        unit=" ML"
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
              </>
            )}
          </div>
          }
        </>
      )}
     

      {/* start-billing-graph */}
      {/* {user.role != "Customer" && */}
      {!tenantConfig.isDisabledBillingChart && (
        <>
          <div className="container-fluid content">
            <h3 className="sub-title">Billing Consumption</h3>
            <div className="form-group">
              <label>Date range</label>
              <DateRangePicker
                setting={{
                  startDate: dateRangeBilling[0],
                  endDate: dateRangeBilling[1],
                }}
                onApply={onApplyBillingUsage}
              >
                <input type="text" className="form-control" />
              </DateRangePicker>
            </div>
            {isNilOrEmpty(billingData) ? (
              <div className="no-item-message">
                {" "}
                There is currently no usage data available.
              </div>
            ) : (
              <>
              <a className="btn btn-primary btn-sm" onClick={handleBillingDownload}>Download as image</a>
              <div className="card" id="billing-chart">
                <div className="card-body">
                  <ResponsiveContainer width="100%" aspect={10.0 / 3.0}>
                    <BarChart
                      data={billingData}
                      margin={{
                        top: 20,
                        right: 5,
                        left: -15,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid stroke="#e9ebf1" />
                      <XAxis dataKey="date" stroke="#e9ebf1" type={"category"} />
                      <YAxis
                        dataKey={"usage"}
                        stroke="#e9ebf1"
                        tickFormatter={yFormatter}
                        label={yLabel}
                      />
                      <Tooltip
                        contentStyle={{
                          boxShadow: "0 10px 24px rgba(29,42,68,0.12)",
                          borderRadius: "4px",
                          border: "none",
                          pointerEvents: 'auto'
                        }}
                        itemStyle={{
                          color: "#05d6f",
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        content={renderTooltip2}
                      />
                      <Bar
                        dataKey={"usage"}
                        fill="#09A3B3"
                        radius={[5, 5, 0, 0]}
                        barSize={24}
                        unit=" ML"
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
              </>
            )}
          </div>
        </>
      )}
      {/* } */}
      {/* end-billing-graph */}
      
      {/* Start Quarterly usage */}
      {tenantConfig.isEnabledQuarterlyChart && (
        <div className="container-fluid content">
          <h3 className="sub-title">Quarterly usage</h3>
          <div className="form-group">
            <label>Date range</label>
            <DateRangePicker
              setting={{
                startDate: dateRangeQuarterly[0],
                endDate: dateRangeQuarterly[1],
              }}
              onApply={onApplyQuarterlyUsage}
            >
              <input type="text" className="form-control" />
            </DateRangePicker>
          </div>
          {isNilOrEmpty(dataQuarterly) ? (
            <div className="no-item-message">
              {" "}
              There is currently no usage data available.
            </div>
          ) : (
            <>
            <a className="btn btn-primary btn-sm" onClick={handleQuarterlyDownload}>Download as image</a>
            <div className="card" id="quarterly-usage-chart">
                <div className="card-body">
                <ResponsiveContainer width="100%" aspect={10.0 / 3.0}>
                    <BarChart
                      data={dataQuarterly}
                      margin={{
                        top: 20,
                        right: 5,
                        left: -15,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid stroke="#e9ebf1" />
                      <XAxis dataKey="date" stroke="#e9ebf1" type={"category"} />
                      <YAxis
                        dataKey={"usage"}
                        stroke="#e9ebf1"
                        tickFormatter={yFormatter}
                        label={yLabel}
                      />
                      <Tooltip
                        contentStyle={{
                          boxShadow: "0 10px 24px rgba(29,42,68,0.12)",
                          borderRadius: "4px",
                          border: "none",
                          pointerEvents: 'auto'
                        }}
                        itemStyle={{
                          color: "#05d6f",
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        content={renderTooltipQuarterlyUsage}
                      />
                      <Bar
                        dataKey={"usage"}
                        fill="#09A3B3"
                        radius={[5, 5, 0, 0]}
                        barSize={24}
                        unit=" ML"
                      />
                    </BarChart>
                  </ResponsiveContainer>
                  {/* <ResponsiveContainer width="100%" aspect={10.0 / 3.0}>
                      <LineChart 
                      height={180}
                      data={dataQuarterly}
                      margin={{
                        top: 20,
                        right: 5,
                        left: -15,
                        bottom: 5,
                      }}
                      barGap={5}
                      barCategoryGap={5}
                    >
                      <CartesianGrid stroke="#e9ebf1" />
                      <XAxis
                        dataKey="date"
                        stroke="#e9ebf1"
                        type={"category"}
                        interval={"preserveStartEnd"}
                        ticks={ticks}
                        tickFormatter={ticksFormatter}
                      />
                      <YAxis
                        stroke="#e9ebf1"
                        domain={[0, dataQuarterlyMax]}
                        label={yLabelSUMs}
                      />
                      {meterNamesQuarterly.map((meterName, index) => (
                        <Line
                          key={index + ""}
                          type="monotone"
                          dataKey={meterName}
                          name={meterName}
                          stroke={index === 0 ? "#09A3B3" : randomColor(index)}
                          dot={false}
                          unit={" L/s"}
                        />
                      ))}
                      <Tooltip
                        contentStyle={tooltipContentStyle}
                        itemStyle={tooltipItemStyle}
                        content={renderTooltip}
                      />
                      <Legend iconType={"circle"} margin={{ top: 50 }} />
                    </LineChart>
                  </ResponsiveContainer> */}
                </div>
              </div>
            </>
          )}
        </div>
      )}
      {/* End Quarterly usage */}
    </>
  );
};

export default CustomersUsagePage;
