import React, { useEffect, useState } from "react";
import { NavLink } from 'react-router-dom';
import { useTenantConfig } from "src/main_app/actions";
import { getRoleName } from 'src/main_app/services/apis';
import { v4 as uuidv4 } from 'uuid';
import { authService } from "../../../base/services";
import { propOr } from "ramda";
const CustomerNav = ({
  customerId = 0,
}) => {
  const tenantConfig = useTenantConfig();
  const user = authService.getUser();
  const [roleName, setRoleName] =  useState();

  const getRoleNameUser = async () => {
    const roleNameUserLogin = await getRoleName();
    setRoleName(propOr([], 'data', roleNameUserLogin))
  }

  useEffect(() => {
    getRoleNameUser();
  }, [roleName]);
  return (
    <nav className="customer-nav">
      <ul className="nav">
          {
            (tenantConfig.customerLeftNav || []).map( field => {
                if (tenantConfig.slug == "intellihub" && roleName == "Customer") {
                } else {
                  if (field === 'Overview') return (
                    <li key={uuidv4()} className="nav-item">
                      <NavLink className="nav-link " to={`/customer/${customerId}/overview`} activeClassName="active" exact={true}>{field}</NavLink>
                    </li>
                  )
                }
                if (field === 'Usage') return (
                  <li key={uuidv4()} className="nav-item">
                    <NavLink className="nav-link" to={`/customer/${customerId}/usage`} activeClassName="active">{field}</NavLink>
                  </li>
                )
                if (field === 'Actions') return (
                  <li key={uuidv4()} className="nav-item">
                    <NavLink className="nav-link" to={`/customer/${customerId}/actions`} activeClassName="active">{field}</NavLink>
                  </li>
                )
                if (field === 'Notes' && roleName != "Customer" ) return (
                  <li key={uuidv4()} className="nav-item">
                    <NavLink className="nav-link" to={`/customer/${customerId}/timeline`} activeClassName="active">{field}</NavLink>
                  </li>
                )
                if (field === 'Alerts') return (
                  <li key={uuidv4()} className="nav-item">
                    <NavLink className="nav-link" to={`/customer/${customerId}/alerts`} activeClassName="active">{field}</NavLink>
                  </li>
                )
            })
          }
      </ul>
    </nav>
  )
}

export default CustomerNav
