import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { find, isEmpty, pathOr, propEq, propOr } from "ramda";
import {
  AddressAutoComplete,
  Checkbox,
  Select1,
  Textbox,
} from "src/main_app/components/form_elements";
import { TagInput } from "src/main_app/components";
import CustomerContact from "./customer-contact.jsx";
import CustomerDevices from "./customer-devices.jsx";
import CustomerBackFlowDevices from "./customer-backflow-devices.jsx";
import { isNilOrEmpty, jsonStringToValue, formatDateDDMMMYYYY, generateAddress } from "src/main_app/utils/common.js";
import { Dropdown, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import CustomerAddress from "src/main_app/components/customer-address/index.js";
import CustomerTradeWasteDeed from "src/main_app/components/customer-trade-waste-deed/index.js";
import { authService } from 'src/base/services';
import { DateRangePicker } from "src/main_app/components/date_range_picker";
import { useParams } from "react-router-dom";
import CustomerModalQRCode from "src/main_app/components/customer-qrcode/index.js";
import { faker } from "@faker-js/faker";

const CustomerSections = ({
  canEditCustomer,
  customer_section,
  customerData,
  customerContactsData,
  onRemoveContact,
  onContactChange,
  onCustomerChange,
  onCustomerFieldChange,
  onAddressUpdate,
  addCustomerInfoValidate,
  addCustomerContactValidate,
  customerDataErrors,
  customerContactsErrors,
  setContactDefault,
  listAnzsicCode,
  tenantConfig,
  roleName,
  customerDevicesData,
  customerBackFlowDevicesData,
  onRemoveBackFlowDevices,
  onBackFlowDevicesChange,
  onRemoveDevices,
  onDevicesChange,
  addNewDevices,
  addNewBackFlowDevices,
  setDevicesDefault,
  setBackFlowDevicesDefault,
  tradeWasteDeedData,
  onCustomerTradeWasteDeedPdfChange,
  onCustomerTradeWasteDeedPdfFieldChange,
  // handleSubmitPopupDeed
}) => {

//modal
const params = useParams();
const [displayModalAddress, setDisplayModaAddress] = useState(false);
const [displayModalTradeWasteDeed, setDisplayModaTradeWasteDeed] = useState(false);
const [displayModalQRCode, setDisplayModaQRCode] = useState(false);
const [checkBoxTradeWaste, setCheckBoxTradeWaste] = useState(false);
const [tradeWasteForm, setTradeWasteForm] = useState(false);

const tenantName = tenantConfig.slug;
const randomAddress = generateAddress();

const showModalAddress = useCallback(
  () => setDisplayModaAddress(true),
  [setDisplayModaAddress]
);

const handleAddressClose = useCallback(
  () => setDisplayModaAddress(false),
  [setDisplayModaAddress]
);

const handleQRCodeClose = useCallback(
  () => setDisplayModaQRCode(false),
  [setDisplayModaQRCode]
);

const showModalTradeWasteDeed = useCallback(
  () => setDisplayModaTradeWasteDeed(true),
  [setDisplayModaTradeWasteDeed]
);

const showModalQRCode = useCallback(
  () => setDisplayModaQRCode(true),
  [setDisplayModaQRCode]
);

const handleTradeWasteDeedClose = useCallback(
  () => setDisplayModaTradeWasteDeed(false),
  [setDisplayModaTradeWasteDeed]
);
const user = authService.getUser();

  const setContactDefaultValue = (section_config) => {
    pathOr([], ["field_data", "fields"], section_config[0]).map((field) => {
      if (pathOr(null, ["field_data", "default_value"], field)) {
        setContactDefault(
          pathOr(false, ["field_data", "name"], field),
          pathOr(false, ["field_data", "default_value"], field)
        );
      } else {
        setContactDefault(pathOr(false, ["field_data", "name"], field), "");
      }
    });
  };

  const render_row = (fields, rowIndex) => {
    if (isEmpty(fields)) return null;
    return (
      <div key={rowIndex + ""} className="row align-items-center">
        {fields
          .filter(pathOr(true, ["field", "field_data", "visible"]))
          .map(({ field, index, currentValue }) => {
            addCustomerInfoValidate(
              pathOr("", ["field_data", "name"], field),
              pathOr(null, ["field_data", "validation"], field),
              pathOr("", ["field_data", "title"], field)
            );
            const validate = find(
              propEq("name", pathOr("", ["field_data", "name"], field))
            )(customerDataErrors);
            switch (propOr(null, "field_type", field)) {
              case "text_input":
                return (
                  <div
                    key={index}
                    className={
                      `col-md-${pathOr(
                        "",
                        ["field_data", "layout_columns"],
                        field
                      )} ` +
                      `${
                        propOr(true, "validate", validate) !== true
                          ? "has-error"
                          : ""
                      }`
                    }
                  >
                    <Textbox
                      controlId={pathOr("", ["field_data", "name"], field)}
                      name={pathOr("", ["field_data", "name"], field)}
                      label={pathOr("", ["field_data", "title"], field)}
                      defaultValue={jsonStringToValue(currentValue)}
                      onChange={onCustomerChange}
                      disabled={pathOr(
                        false,
                        ["field_data", "readOnly"],
                        field
                      )}
                    />
                  </div>
                );
              case "number_input":
                return (
                  <div
                    key={index}
                    className={
                      `col-md-${pathOr(
                        "",
                        ["field_data", "layout_columns"],
                        field
                      )} ` +
                      `${
                        propOr(true, "validate", validate) !== true
                          ? "has-error"
                          : ""
                      }`
                    }
                  >
                    <Textbox
                      controlId={pathOr("", ["field_data", "name"], field)}
                      name={pathOr("", ["field_data", "name"], field)}
                      label={pathOr("", ["field_data", "title"], field)}
                      type="number"
                      defaultValue={pathOr("", ["field_data", "default_value"], field) ? 0.85 : jsonStringToValue(currentValue)}
                      onChange={onCustomerChange}
                      disabled={pathOr(
                        false,
                        ["field_data", "readOnly"],
                        field
                      )}
                    />
                  </div>
                );
              case "tag_input":
                return (
                  <div
                    key={index}
                    className={
                      `col-md-${pathOr(
                        "",
                        ["field_data", "layout_columns"],
                        field
                      )} ` +
                      `${
                        propOr(true, "validate", validate) !== true
                          ? "has-error"
                          : ""
                      }`
                    }
                  >
                    <TagInput
                      label={pathOr("", ["field_data", "title"], field)}
                      value={currentValue}
                      onChange={onCustomerFieldChange(
                        pathOr("", ["field_data", "name"], field)
                      )}
                      disabled={pathOr(
                        false,
                        ["field_data", "readOnly"],
                        field
                      )}
                    />
                  </div>
                );
              case "address_autocomplete":
                return (
                  <div
                    key={index}
                    className={
                      `col-md-${pathOr(
                        "",
                        ["field_data", "layout_columns"],
                        field
                      )} ` +
                      `${
                        propOr(true, "validate", validate) !== true
                          ? "has-error"
                          : ""
                      }`
                    }
                  >
                    <AddressAutoComplete
                      controlId={pathOr("", ["field_data", "name"], field)}
                      name={pathOr("", ["field_data", "name"], field)}
                      label={pathOr("", ["field_data", "title"], field)}
                      currentAddress={tenantName === "intellihub" ? randomAddress : currentValue}
                      onAddressUpdate={onAddressUpdate}
                      roleName={roleName}
                    />
                  </div>
                );
              case "select":
                return (
                  <div
                    key={index + ""}
                    className={
                      `col-md-${pathOr(
                        "",
                        ["field_data", "layout_columns"],
                        field
                      )} ` +
                      `${
                        propOr(true, "validate", validate) !== true
                          ? "has-error"
                          : ""
                      }`
                    }
                  >
                    <Select1
                      label={pathOr("", ["field_data", "title"], field)}
                      name={pathOr("", ["field_data", "name"], field)}
                      items={pathOr("", ["field_data", "name"], field) == 'anzsic_code' ? listAnzsicCode : pathOr("", ["field_data", "options"], field)}
                      hasEmptyOption={true}
                      value={isNilOrEmpty(currentValue) ? "" : jsonStringToValue(currentValue)}
                      onChange={ e => onCustomerFieldChange(pathOr("", ["field_data", "name"], field))(e.value) }
                      disabled={pathOr(
                        false,
                        ["field_data", "readOnly"],
                        field
                      )}
                    />
                  </div>
                );
              case "checkbox":
                return (
                  <div
                    key={index + ""}
                    className={
                      `col-md-${pathOr("", ["field_data", "layout_columns"], field)} ` +
                      `${
                        propOr(true, "validate", validate) !== true ? "has-error" : ""
                      }`
                    }
                  >
                    <Checkbox
                      label={pathOr("", ["field_data", "title"], field)}
                      name={pathOr("", ["field_data", "name"], field)}
                      controlId={pathOr("", ["field_data", "name"], field)}
                      checked={isEmpty(currentValue) ? false : currentValue === "false" ? false : currentValue}
                      // checked={!isEmpty(currentValue) ? currentValue : false}
                      onChange={ e => onCustomerFieldChange(pathOr("", ["field_data", "name"], field))(e.target.checked) }
                    />
                  </div>
                );
                case "button":
                return (
                  <div key={index + ""} className={`col-md-${pathOr("",["field_data", "layout_columns"],field)} `}>
                    <OverlayTrigger overlay={<Tooltip>Is this address correct? If not, please notify us</Tooltip>}>
                      <a className="btn-text" onClick={showModalAddress}><i className="fas fa-info-circle" style={{fontSize: "15px"}}></i></a>
                    </OverlayTrigger>
                    <CustomerAddress
                      tenantConfig={tenantConfig}
                      showModal={displayModalAddress}
                      handleActionsClose={handleAddressClose}
                      user={user}
                      // handleSubmitPopupDeed={handleSubmitPopupDeed}
                    />
                  </div>
                );
                case "checkbox_trade_waste":
                  return render_trade_waste_form(field, currentValue, fields)
                case "checkbox_backflow_prevention_devices":
                  return render_backflow_form(field, currentValue, fields)
                case "date_input":
                  const valueDate = currentValue ? formatDateDDMMMYYYY(currentValue) : false;
                  return (
                    <div key={index + ""} className={`col-md-${pathOr("",["field_data", "layout_columns"],field)} `}>
                      <label>{pathOr("", ["field_data", "title"], field)}</label>
                      <DateRangePicker
                        setting={{
                          singleDatePicker: true,
                          autoUpdateInput: !!valueDate,
                          startDate: valueDate,
                        }}
                        onApply={(event, picker) => {
                          const nDate = picker.startDate.utcOffset(0, true);
                          picker.element.val(nDate.format("DD MMM YYYY"));
                          onCustomerFieldChange(pathOr("", ["field_data", "name"], field))(nDate.toDate());
                        }}
                      >
                        <input type="text" className="form-control" />
                    </DateRangePicker>
                    </div>
                  )
              default:
                return null;
            }
          })}
      </div>
    );
  };

  const render_trade_waste_form = (field, currentValue, fields) => {
    const dataTradeWastePercent = customerData['trade_waste_discharge_factor_percent'] == null ? "false" : "true";
    const optionTradeWaste = pathOr([], ["options"], field)
    const dataTradeWasteCheckbox = isEmpty(currentValue) ? false : currentValue === "false" ? false : currentValue;
    const checkedBox = dataTradeWasteCheckbox && dataTradeWastePercent
    return (
      <>
          <div className="col-md-3">
          <Checkbox
            label={pathOr("", ["field_data", "title"], field)}
            name={pathOr("", ["field_data", "name"], field)}
            controlId={pathOr("", ["field_data", "name"], field)}
            checked={dataTradeWasteCheckbox}
            onChange={ e => onCustomerFieldChange(pathOr("", ["field_data", "name"], field))(e.target.checked) }
          />
            {/* <Checkbox
              label={pathOr("", ["field_data", "title"], field)}
              name={pathOr("", ["field_data", "name"], field)}
              controlId={pathOr("", ["field_data", "name"], field)}
              checked={checkBoxTradeWaste}
              onChange={ e => onChangeCheckBoxTradeWaste(e.target.checked)}
            /> */}
          </div>
          <div className="col-md-3" style={{color: "#0071db", position: "relative", right: "5%"}}>
            <a onClick={showModalTradeWasteDeed}><i className="fal fa-file-pdf" style={{fontSize: "17px"}}></i> <span style={{textDecoration: "underline"}}> {tradeWasteDeedData['deed_status_update'] ? 'Update' : 'Create'} trade waste deed</span></a>
          </div>
          <CustomerTradeWasteDeed
            tenantConfig={tenantConfig}
            showModal={displayModalTradeWasteDeed}
            handleTradeWasteDeedClose={handleTradeWasteDeedClose}
            user={user}
            tradeWasteDeedData={tradeWasteDeedData}
            onCustomerTradeWasteDeedPdfChange={onCustomerTradeWasteDeedPdfChange}
            onCustomerTradeWasteDeedPdfFieldChange={onCustomerTradeWasteDeedPdfFieldChange}
          />
        {/* {checkBoxTradeWaste &&  */}
        {dataTradeWasteCheckbox && 
          <div className="form-group-block mb-3 card col-md-12">
            <div className="card-body d-flex">
                <div className="flex-grow-1">
                  <div className="col-md-12 align-items-center">
                  {optionTradeWaste.map((customer_section, index) => (
                    <>
                      <h3 key={index} className="sub-title mt-4">
                        {propOr("", "section_name", customer_section)}
                      </h3>
                      {propOr("", "section_name", customer_section) == 'Devices' ? render_devices_fields(customer_section, customerDevicesData) : render_section_fields(customer_section, customerData)}
                    </>
                  ))}
                  </div>
                </div>
            </div>
          </div>
        }
      </>
    )
  }


  const render_backflow_form = (field, currentValue, fields) => {
    const optionBackFlow = pathOr([], ["options"], field)
    const dataBackFlowCheckbox = isEmpty(currentValue) ? false : currentValue === "false" ? false : currentValue;
    return (
      <>
          <div className="col-md-3">
          <Checkbox
            label={pathOr("", ["field_data", "title"], field)}
            name={pathOr("", ["field_data", "name"], field)}
            controlId={pathOr("", ["field_data", "name"], field)}
            checked={dataBackFlowCheckbox}
            onChange={ e => onCustomerFieldChange(pathOr("", ["field_data", "name"], field))(e.target.checked) }
          />
          </div>
          <CustomerModalQRCode
            showModal={displayModalQRCode}
            handleActionsClose={handleQRCodeClose}
          />
          {dataBackFlowCheckbox && (
            <>
              <div className="form-group-block mb-3 card col-md-12">
                <div className="card-body d-flex">
                    <div className="flex-grow-1">
                      <div className="col-md-12 align-items-center">
                      {optionBackFlow.map((customer_section, index) => (
                        <>
                          <h3 key={index} className="sub-title mt-4">
                            {propOr("", "section_name", customer_section)}
                          </h3>
                          {propOr("", "section_name", customer_section) == 'Devices' ? render_backflow_devices_fields(customer_section, customerBackFlowDevicesData) : ''}
                        </>
                      ))}
                      </div>

                      <div className="col-md-12 align-items-center" style={{color: "#0071db"}}>
                        <a onClick={showModalQRCode}><i className="fal fa-qrcode" style={{fontSize: "17px"}}></i> <span style={{textDecoration: "underline"}}>QR code</span></a>
                      </div>
                    </div>
                </div>
              </div>
            </>
          )}
          
      </>
    )
  }

const onChangeCheckBoxTradeWaste = (e) => {
  setCheckBoxTradeWaste(e);
}

  const isContactSection = (customer_section) => {
    return (
      !isEmpty(customer_section) &&
      customer_section.fields &&
      customer_section.fields[0] &&
      propOr("", "field_type", customer_section.fields[0]) === "contacts"
    );
  };

  const process_rows = (customer_section, customerData) => {
    let layout_columns = 0;
    let fields = [];
    let rows = [];
    propOr([], "fields", customer_section).forEach((field, index) => {
      layout_columns += pathOr(0, ["field_data", "layout_columns"], field);
      if (layout_columns > 12) {
        rows.push(fields);
        layout_columns = pathOr(0, ["field_data", "layout_columns"], field);
        fields = [];
      }
      fields.push({
        field,
        index,
        currentValue: customerData[pathOr("", ["field_data", "name"], field)],
      });
    });
    if (!isEmpty(fields)) {
      rows.push(fields);
    }
    return rows;
  };

  const render_section_fields = (customer_section, customerData) => {
    const rows = process_rows(customer_section, customerData);
    return rows.map(render_row);
  };

  const render_contacts_fields = (customer_section, customerContactsData) => {
    setContactDefaultValue(propOr(null, "fields", customer_section));
    return customerContactsData.map(
      (contact, contact_index) =>
        !contact.isRemoved && (
          <CustomerContact
            key={contact_index + ""}
            canEditCustomer={canEditCustomer}
            onRemove={onRemoveContact(contact)}
            onChange={onContactChange(contact)}
            section_config={pathOr([], ["fields"], customer_section)}
            addCustomerContactValidate={addCustomerContactValidate}
            contact={contact}
            customerContactErrors={customerContactsErrors[contact_index]}
            setContactDefault={setContactDefault}
          />
        )
    );
  };

  const render_devices_fields = (customer_section, customerDevicesData) => {
    setContactDefaultValue(propOr(null, "fields", customer_section));
    return (
    <>
      {customerDevicesData.map(
        (devices, devices_index) =>
          !devices.isRemoved && (
            <CustomerDevices
              key={devices_index + ""}
              onRemove={onRemoveDevices(devices)}
              onChange={onDevicesChange(devices)}
              section_config={pathOr([], ["fields"], customer_section)}
              devices={devices}
              setDevicesDefault={setDevicesDefault}
            />
          )
      )}
      <div className="text-right mb-4" style={{position: "relative", paddingRight: "34%"}}>
        <a className="underline" onClick={addNewDevices}>
          Add another
        </a>
      </div>
    </>
    )
  };

  const render_backflow_devices_fields = (customer_section, customerBackFlowDevicesData) => {
    setContactDefaultValue(propOr(null, "fields", customer_section));
    return (
    <>
      {customerBackFlowDevicesData.map(
        (devices, devices_index) =>
          !devices.isRemoved && (
            <CustomerBackFlowDevices
              key={devices_index + ""}
              onRemove={onRemoveBackFlowDevices(devices)}
              onChange={onBackFlowDevicesChange(devices)}
              section_config={pathOr([], ["fields"], customer_section)}
              devices={devices}
              setDevicesDefault={setDevicesDefault}
            />
          )
      )}
      <div className="text-right mb-4" style={{position: "relative"}}>
        <a className="underline" onClick={addNewBackFlowDevices}>
          Add another
        </a>
      </div>
    </>
    )
  };

  if (isEmpty(customerData) || isEmpty(customer_section)) return null;

  return (
    <React.Fragment>
      <h3 className="sub-title mt-4">
        {propOr("", "section_name", customer_section)}
      </h3>
      {isContactSection(customer_section)
        ? render_contacts_fields(customer_section, customerContactsData)
        : render_section_fields(customer_section, customerData)}
    </React.Fragment>
  );
};

export default CustomerSections;
