import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import {
  filter,
  indexOf,
  propOr,
  includes,
  reject,
  prop,
  map,
  find,
  compose,
  equals,
  isEmpty,
  isNil,
  empty,
} from "ramda";
import { useParams } from "react-router-dom";
import update from "immutability-helper";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router";

import {
  getCustomerData,
  updateCustomer,
  queryCustomerSiteContactData,
  getCustomerTradeWasteDataDetail,
  getCustomerTradeWasteDataFormPopup,
} from "../../../../utils/customer_utils";
import {
  filterRemovedItems,
  getTargetChecked,
  getTargetValue,
  inputChangeHandler,
  targetIsCheckbox,
} from "../../../../utils/react_utils";
import {
  addCustomerSiteContact,
  removeCustomerSiteContact,
  updateCustomerSiteContact,
} from "src/main_app/services/apis";

import CustomerSections from "./customer-sections";
import CustomerOverviewSkeleton from "./customer-overview-skeleton";
import CustomerHelpfulLinks from "./customer-helpful-links";
import CustomerExemption from "./customer-exemption";
import CustomerCategories from "./customer-categories";
import { getListAnzsicCode, getRoleName, customerAddOrUpdateMeta } from "src/main_app/services/apis";

import { track } from "src/main_app/mixpanel";
import {
  checkPermission,
  useTenantConfig,
  useUserPermissions,
} from "src/main_app/actions";
import moment from 'moment'
import { formatDateDDMMMYYYY } from "src/main_app/utils/common";
import { getTenantInfoFromLocal } from "src/main_app/services/local_storage/tenant-info";

const CustomerOverviewPage = (props) => {
  const tenantInfo = useRef(getTenantInfoFromLocal());
  useEffect(() => {
    track("Customer overview page", {
      tenant_id: tenantInfo.current.id,
      tenant_name: tenantInfo.current.name,
    });
  }, []);
  const tenantConfig = useTenantConfig();
  const { addToast } = useToasts();
  const history = useHistory();
  const params = useParams();
  const [customerData, setCustomerData] = useState({});
  const [customerContactsData, setCustomerContactsData] = useState([]);
  const [customerDevicesData, setCustomerDevicesData] = useState([]);
  const [customerBackFlowDevicesData, setCustomerBackFlowDevicesData] = useState([]);
  // const [customerDevicesData, setCustomerDevicesData] = useState([
  //   {
  //     devices_capacity: null,
  //     devices_type: 'BASKET-TRAP'
  //   }
  // ]);
  const previousContactData = useRef(null);
  const [customerDataErrors, setCustomerDataErrors] = useState([]);
  const [customerContactsErrors, setCustomerContactsErrors] = useState([]);
  const [listAnzsicCode, setListAnzsicCode] = useState([]);
  const customerInfoValidate = useRef({});
  const customerContactValidate = useRef({});
  const customerContactDefault = useRef({});
  const customerDevicesDefault = useRef({});
  const customerBackFlowDevicesDefault = useRef({});
  const [tradeWasteDeedData, setTradeWasteDeedData] = useState({})
  const [roleName, setRoleName] =  useState();

  const userPermissions = useUserPermissions();
  const canEditCustomer = useMemo(
    () => checkPermission(userPermissions, "customers-page", "canEdit"),
    [userPermissions]
  );

  const onCustomerTradeWasteDeedPdfChange = inputChangeHandler(tradeWasteDeedData, setTradeWasteDeedData);
  const onCustomerTradeWasteDeedPdfFieldChange = (fileName) => (value) => {
    setTradeWasteDeedData({
      ...tradeWasteDeedData,
      [fileName]: value,
    });
  };

  // const handleSubmitPopupDeed = () => {
  //   console.log('23', tradeWasteDeedData)
  // }

  const setContactDefault = (name, value) => {
    customerContactDefault.current = {
      ...customerContactDefault.current,
      [name]: value,
    };
  };

  const setDevicesDefault = (name, value) => {
    customerDevicesDefault.current = {
      ...customerDevicesDefault.current,
      [name]: value,
    };
  };

  const setBackFlowDevicesDefault = (name, value) => {
    customerBackFlowDevicesDefault.current = {
      ...customerBackFlowDevicesDefault.current,
      [name]: value,
    };
  };

  const addCustomerInfoValidate = (name, rules, title) => {
    customerInfoValidate.current = {
      ...customerInfoValidate.current,
      [name]: { rules, title },
    };
  };

  const addCustomerContactValidate = (name, rules, title) => {
    customerContactValidate.current = {
      ...customerContactValidate.current,
      [name]: { rules, title },
    };
  };

  const onCustomerChange = inputChangeHandler(customerData, setCustomerData);

  const onCustomerFieldChange = (fileName) => (value) => {
    setCustomerData({
      ...customerData,
      [fileName]: value,
    });
  };

  const updateCustomerContacts = async (customerID) => {
    const urlDetailCustomer = window.location.href;
    const removedItems = filterRemovedItems(customerContactsData);
    await Promise.all(
      map(compose(removeCustomerSiteContact, prop("id")), removedItems)
    );
    // await Promise.all(map(removeCustomerSiteContact(prop("id"), urlDetailCustomer), removedItems));
    const newItems = filter((i) => !i.id, customerContactsData);
    await Promise.all(map(addCustomerSiteContact(customerID, urlDetailCustomer), newItems));

    const updateItems = reject(
      (i) =>
        includes(i, removedItems) ||
        includes(i, newItems) ||
        find(equals(i))(previousContactData.current),
      customerContactsData
    );
    await Promise.all(
      map(
        (item) => updateCustomerSiteContact(prop("id", item), urlDetailCustomer)(item),
        updateItems
      )
    );
  };

  const updateCustomerDevices = async (customerID) => {
    const type = 'customer_devices';
    const res = await customerAddOrUpdateMeta(customerID, type, customerDevicesData)
  };

  const updateCustomerBackFlowDevices = async (customerID) => {
    const type = 'customer_backflow_devices';
    const res = await customerAddOrUpdateMeta(customerID, type, customerBackFlowDevicesData)
  };

  const validateValue = (value, { rules, title }) => {
    if (isEmpty(rules) || isNil(rules)) {
      return true;
    }
    let errors = [];
    Object.entries(rules).forEach(([rule_name, rule_value]) => {
      switch (rule_name) {
        case "required":
          if (rule_value && (isEmpty(value) || isNil(value))) {
            errors.push(`${title} is required.`);
          }
          break;
        case "max_length":
          if (propOr(0, "length", value) > rule_value) {
            errors.push(`${title} has max length of ${rule_value} letters`);
          }
          break;
        case "min_length":
          if (propOr(100000, "length", value) < rule_value) {
            errors.push(`${title} has min length of ${rule_value} letters`);
          }
          break;
        case "max_value":
          if (value > rule_value) {
            errors.push(`${title} must be smaller than ${rule_value}`);
          }
          break;
        case "min_value":
          if (value < rule_value) {
            errors.push(`${title} must be greater than ${rule_value}`);
          }
          break;
      }
    });
    if (isEmpty(errors)) {
      return true;
    } else {
      return errors;
    }
  };

  const validateCustomerInfo = () => {
    let allValidated = true;
    const customerDetailErrors = Object.entries(customerData).map(
      ([name, value]) => {
        const validate = validateValue(
          value,
          propOr({ rules: null, title: "" }, name, customerInfoValidate.current)
        );
        if (validate !== true) {
          allValidated = false;
          validate.map((error) => {
            addToast(error, {
              appearance: "error",
              autoDismiss: true,
              autoDismissTimeout: 3000,
            });
          });
        }
        return { name: name, validate: validate };
      }
    );
    setCustomerDataErrors(customerDetailErrors);
    return allValidated;
  };

  const validateCustomerContacts = () => {
    let allValidated = true;
    const contactErrorsList = [];
    customerContactsData.map((contact, contact_index) => {
      const customerContactsErrors = Object.entries(contact).map(
        ([name, value]) => {
          const validate = contact.isRemoved
            ? true
            : validateValue(
                value,
                propOr(
                  { rules: null, title: "" },
                  name,
                  customerContactValidate.current
                )
              );
          if (validate !== true) {
            allValidated = false;
            validate.map((error) => {
              addToast(`Contact number ${contact_index + 1}: ${error}`, {
                appearance: "error",
                autoDismiss: true,
                autoDismissTimeout: 3000,
              });
            });
          }
          return { name, validate };
        }
      );
      contactErrorsList.push(customerContactsErrors);
    });
    setCustomerContactsErrors(contactErrorsList);
    return allValidated;
  };

  const validateTimeTradeWaste = () => {
    let allValidated = true;
    const fromDate = customerData['permit_start'];
    const toDate = customerData['expiry_date'];
    // check fromDate valid
    if ((fromDate != undefined) && (toDate != undefined)) {
      const isFromDateBeforeToDate = moment(formatDateDDMMMYYYY(fromDate)).isSameOrBefore(moment(formatDateDDMMMYYYY(toDate)))// boolean
      if (!isFromDateBeforeToDate) {
        allValidated = false;
        addToast("Permit start must be before the Expiry date", {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
      }
    }
   
    return allValidated;
  };

  const validateContactUserSignIn = () => {
    let allValidated = true;
    customerContactsData.map((contact, contact_index) => {
      const validate = contact.isRemoved ? true : 
      val(contact, contact_index)
      if (validate !== true) {
        allValidated = false;
      }
    });
   return allValidated
  }

  const val = (contact, contact_index) =>{
    let allValidated = true;
    const isUserSignIn = contact['is_user_sign_in'];
    if (isUserSignIn) {
      const email = contact['email'];
        if (isEmpty(email)) {
          allValidated = false;
          return addToast(`Contact number ${contact_index + 1}: email is required.`, {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 3000,
          });
        }
      }
    return allValidated;
  }

  const submitHandler = async (event) => {
    event.preventDefault();
    const infoValidated = validateCustomerInfo();
    const customerTradeWasteValidated = validateTimeTradeWaste()
    const contactValidated = validateCustomerContacts();
    const contactValidatedUserSignIn = validateContactUserSignIn()
    if (!infoValidated || !contactValidated || !customerTradeWasteValidated || !contactValidatedUserSignIn) return;

    await updateCustomer(params.id, customerData);
    await updateCustomerContacts(params.id);
    await updateCustomerDevices(params.id);
    await updateCustomerBackFlowDevices(params.id);
    await loadData();
    await props.reloadInfo(params.id);
    addToast("Updated successfully.", {
      appearance: "success",
      autoDismiss: true,
      autoDismissTimeout: 3000,
    });
    setTimeout(() => {
    // window.location.reload()
    }, 3000);
  };

  const addNewContact = () => {
    setCustomerContactsData([
      ...customerContactsData,
      customerContactDefault.current,
    ]);
  };

  const addNewDevices = () => {
    setCustomerDevicesData([
      ...customerDevicesData,
      customerDevicesDefault.current,
    ]);
  };

  const addNewBackFlowDevices = () => {
    setCustomerBackFlowDevicesData([
      ...customerBackFlowDevicesData,
      customerBackFlowDevicesDefault.current,
    ]);
  };

  const onRemoveContact = (contact) => (event) => {
    const index = indexOf(contact, customerContactsData);
    const updateCondition = contact.id
      ? {
          [index]: {
            isRemoved: {
              $set: true,
            },
          },
        }
      : {
          $splice: [[index, 1]],
        };
    const newCustomerContactsData = update(
      customerContactsData,
      updateCondition
    );
    setCustomerContactsData(newCustomerContactsData);
  };

  const onContactChange = (contact) => (event) => {
    const index = indexOf(contact, customerContactsData);
    let newCustomerContactsData  = [];
    if (event.value) {
      newCustomerContactsData = update(customerContactsData, {
      [index]: {
        ['type']: {
          $set: targetIsCheckbox(event)
            ? getTargetChecked(event)
            : event.value,
        },
      },
    });
    } else {
      newCustomerContactsData = update(customerContactsData, {
      [index]: {
        [event.target.name]: {
          $set: targetIsCheckbox(event)
            ? getTargetChecked(event)
            : getTargetValue(event),
        },
      },
    });
    }
    // const newCustomerContactsData = update(customerContactsData, {
    //   [index]: {
    //     [event.target.name]: {
    //       $set: targetIsCheckbox(event)
    //         ? getTargetChecked(event)
    //         : getTargetValue(event),
    //     },
    //   },
    // });
    setCustomerContactsData(newCustomerContactsData);
  };

  const onRemoveDevices = (devices) => (event) => {
    const index = indexOf(devices, customerDevicesData);
    const updateCondition = devices.id
      ? {
          [index]: {
            isRemoved: {
              $set: true,
            },
          },
        }
      : {
          $splice: [[index, 1]],
        };
    const newCustomerDevicesData = update(
      customerDevicesData,
      updateCondition
    );
    setCustomerDevicesData(newCustomerDevicesData);
  };

  const onDevicesChange = (devices) => (event) => {
    const index = indexOf(devices, customerDevicesData);
    let newCustomerDevicesData  = [];
    if (event.value) {
      newCustomerDevicesData = update(customerDevicesData, {
      [index]: {
        ['devices_type']: {
          $set: targetIsCheckbox(event)
            ? getTargetChecked(event)
            : event.value,
        },
      },
    });
    } else {
      newCustomerDevicesData = update(customerDevicesData, {
      [index]: {
        [event.target.name]: {
          $set: targetIsCheckbox(event)
            ? getTargetChecked(event)
            : getTargetValue(event),
        },
      },
    });
    }
    setCustomerDevicesData(newCustomerDevicesData);
  };

  const onRemoveBackFlowDevices = (devices) => (event) => {
    const index = indexOf(devices, customerBackFlowDevicesData);
    const updateCondition = devices.id
      ? {
          [index]: {
            isRemoved: {
              $set: true,
            },
          },
        }
      : {
          $splice: [[index, 1]],
        };
    const newCustomerBackFlowDevicesData = update(
      customerBackFlowDevicesData,
      updateCondition
    );
    setCustomerBackFlowDevicesData(newCustomerBackFlowDevicesData);
  };

  const onBackFlowDevicesChange = (devices) => (event, value) => {
    const index = indexOf(devices, customerBackFlowDevicesData);
    let newCustomerBackFlowDevicesData  = [];
    if (event.value) {
      newCustomerBackFlowDevicesData = update(customerBackFlowDevicesData, {
      [index]: {
        ['backflow_devices_number']: {
          $set: targetIsCheckbox(event)
            ? getTargetChecked(event)
            : event.value,
        },
      },
    });
    } else if (!isNil(value)) {
      newCustomerBackFlowDevicesData = update(customerBackFlowDevicesData, {
      [index]: {
        [event]: {
          $set: value,
        },
      },
    });
    } else {
      newCustomerBackFlowDevicesData = update(customerBackFlowDevicesData, {
      [index]: {
        [event.target.name]: {
          $set: targetIsCheckbox(event)
            ? getTargetChecked(event)
            : getTargetValue(event),
        },
      },
    });
    }
    setCustomerBackFlowDevicesData(newCustomerBackFlowDevicesData);
  };

  const onAddressUpdate = (address) => {
    setCustomerData({
      ...customerData,
      address: address.address,
      latitude: address.latLng.lat,
      longitude: address.latLng.lng,
      address_object: address.addressObj,
    });
  };

  const loadData = async () => {
    const [customer_data, { data: contacts }] = await Promise.all([
      getCustomerData(params.id),
      queryCustomerSiteContactData(params.id)(1, 1000, null, null, {
        "sort[0][0]": "id",
        "sort[0][1]": "asc",
      }),
    ]);
    const dataBackFlowDevices = await getCustomerTradeWasteDataDetail(params.id, 'customer_backflow_devices');
    const dataDevices = await getCustomerTradeWasteDataDetail(params.id, 'customer_devices');
    const dataTradeWaste = await getCustomerTradeWasteDataFormPopup(params.id, 'customer_deed_form_popup');
    setCustomerData(customer_data);
    setCustomerContactsData(contacts);
    setPreviousContact(contacts);
    setCustomerDevicesData(dataDevices)
    setTradeWasteDeedData(dataTradeWaste)
    setCustomerBackFlowDevicesData(dataBackFlowDevices)
  };

  const setPreviousContact = (customerContacts) => {
    previousContactData.current = customerContacts;
  };

  const goToTimeline = useCallback(() => {
    history.push(`/customer/${params.id}/timeline`);
  }, [params.id]);

  const getAnzsicCode = async () => {
    const {data: result} = await getListAnzsicCode();
    const roleNameUserLogin = await getRoleName();
    setRoleName(propOr([], 'data', roleNameUserLogin))
    setListAnzsicCode(result);
  }

  useEffect(() => {
    getAnzsicCode();
    if (isEmpty(tenantConfig)) return;
    loadData();
  }, [tenantConfig]);

  return isEmpty(customerData) ? (
    <CustomerOverviewSkeleton />
  ) : (
    <div className="container-fluid content">
      <div className="row">
        <div className={!tenantConfig.isDisabledBaseFlowAlerts ? "col-md-9 mb-5" : "col-md-12 mb-5"}>
        {/* <div className="col-md-9 mb-5"> */}
          <form onSubmit={submitHandler}>
            {propOr([], "customer_fields", tenantConfig).map(
              (customer_section, index) => (
                <CustomerSections
                  key={index + ""}
                  canEditCustomer={canEditCustomer}
                  customer_section={customer_section}
                  customerData={customerData}
                  customerContactsData={customerContactsData}
                  onRemoveContact={onRemoveContact}
                  onContactChange={onContactChange}
                  onCustomerChange={onCustomerChange}
                  onCustomerFieldChange={onCustomerFieldChange}
                  onAddressUpdate={onAddressUpdate}
                  addCustomerContactValidate={addCustomerContactValidate}
                  addCustomerInfoValidate={addCustomerInfoValidate}
                  customerContactsErrors={customerContactsErrors}
                  customerDataErrors={customerDataErrors}
                  setContactDefault={setContactDefault}
                  listAnzsicCode={listAnzsicCode}
                  tenantConfig={tenantConfig}
                  roleName={roleName}
                  customerDevicesData={customerDevicesData}
                  customerBackFlowDevicesData={customerBackFlowDevicesData}
                  onRemoveBackFlowDevices={onRemoveBackFlowDevices}
                  onBackFlowDevicesChange={onBackFlowDevicesChange}
                  onRemoveDevices={onRemoveDevices}
                  onDevicesChange={onDevicesChange}
                  addNewDevices={addNewDevices}
                  addNewBackFlowDevices={addNewBackFlowDevices}
                  setDevicesDefault={setDevicesDefault}
                  setBackFlowDevicesDefault={setBackFlowDevicesDefault}
                  tradeWasteDeedData={tradeWasteDeedData}
                  onCustomerTradeWasteDeedPdfChange={onCustomerTradeWasteDeedPdfChange}
                  onCustomerTradeWasteDeedPdfFieldChange={onCustomerTradeWasteDeedPdfFieldChange}
                  // handleSubmitPopupDeed={handleSubmitPopupDeed}
                />
              )
            )}
            {canEditCustomer && tenantConfig.slug != 'maitland' && (
              <div className="text-right mb-4">
                {!tenantConfig.isCanNotAddContact && (
                  <a className="underline" onClick={addNewContact}>
                    Add another contact
                  </a>
                )}
              </div>
            )}
            {canEditCustomer && tenantConfig.slug != 'maitland' && (
              <button className="btn btn--save btn-primary">SAVE</button>
            )}
          </form>
        </div>
        {!tenantConfig.isDisabledBaseFlowAlerts && (
          <div className="col-md-3 secondary-color sidebar-right">
            <CustomerCategories
              customerTaxonomies={prop("customerTaxonomies", customerData)}
            />
            <CustomerHelpfulLinks
              customerId={params.id}
              canEditCustomer={canEditCustomer}
              tenantConfig={tenantConfig}
            />
            <CustomerExemption
              goToTimeline={goToTimeline}
              hasExemption={prop("hasExemption", customerData)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerOverviewPage;
