import React, { useCallback, useMemo, useEffect } from "react";
import moment from "moment";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
  Legend,
} from "recharts";
import { flatten, isNil, values, reject, isEmpty } from "ramda";
import { DateRangePicker } from "src/main_app/components/date_range_picker";
import { formatNumber, randomColor } from "src/main_app/utils/common";
import { useTenantConfig } from "src/main_app/actions/tenant";

const tooltipContentStyle = {
  boxShadow: "0 10px 24px rgba(29,42,68,0.12)",
  borderRadius: "4px",
  border: "none",
  textAlign: "center",
};

const tooltipItemStyle = {
  color: "#05d6f",
  fontSize: "12px",
  fontWeight: "500",
};

const ticksFormatter = (value, index) => 
  moment(value * 1000).format("ddd MMM DD");

const renderTooltip = ({ active, payload, label }) => {
  if (!active || !payload || !payload.length) return null;
  return (
    <div
      className="custom-line-tooltip"
      style={{
        padding: "5px",
        backgroundColor: "#fff",
        boxShadow: "0 0 8px 8px rgb(0 0 0 / 7%)",
        borderRadius: "4px",
      }}
    >
      <h5 className="mb-0">{payload[0].payload.displayDate}</h5>
      <br />
      {payload.map((payloadItem, index) => (
        <div
          key={index + ""}
          style={{
            color: payloadItem.stroke,
          }}
        >
          
          {payloadItem.name}: {formatNumber(payloadItem.value)}{" "}
          {payloadItem.unit}
        </div>
      ))}
    </div>
  );
};


const AlertCustomerRealTimeUsagePage = ({
  dataWeekly,
  meterNames,
  ticks,
  customer
}) => {
  const tenantConfig = useTenantConfig();
  
  const allSumData = useMemo(() => {
    const dataUsage = [];
    // if (isEmpty(dataWeekly)) return
    for (const itemDataWeekly of dataWeekly) {
      const { date, displayDate, ...dataSumUsage } = itemDataWeekly;
      dataUsage.push(values(dataSumUsage));
    }
    return reject(isNil, flatten(dataUsage));
  }, [dataWeekly]);

  const dataWeeklyMax = useMemo(
    () => Math.ceil(Math.max(...allSumData) * 10) / 10,
    [allSumData]
  );


  const yLabelSUMs = useMemo(
    () => ({
      value: "L/s",
      angle: -90,
      position: "top",
      dy: 30,
      dx: -10,
    }),
    []
  );

  return (
    <>
        <div className="container-fluid content" style={{padding:"0 0 5% 0"}}>
            <div className="card">
              <div className="card-body alert-chart">
                <ResponsiveContainer width="100%" aspect={10.0 / 6.0}>
                  <LineChart
                    height={220}
                    data={dataWeekly}
                    margin={{
                      top: 20,
                      right: 5,
                      left: -15,
                      bottom: 5,
                    }}
                    barGap={5}
                    barCategoryGap={5}
                  >
                    <CartesianGrid stroke="#e9ebf1" />
                    <XAxis
                      dataKey="date"
                      stroke="#e9ebf1"
                      type={"category"}
                      interval={"preserveStartEnd"}
                      ticks={ticks}
                      tickFormatter={ticksFormatter}
                    />
                    <YAxis
                      stroke="#e9ebf1"
                      domain={[0, dataWeeklyMax]}
                      label={yLabelSUMs}
                    />
                    {meterNames.map((meterName, index) => (
                      <Line
                        key={index + ""}
                        type="monotone"
                        dataKey={meterName}
                        // name={meterName}
                        name={tenantConfig.isConcatMeterAddress ? meterName + " - " + customer.address + `, ${customer.suburb_name}` : meterName}
                        stroke={index === 0 ? "#09A3B3" : randomColor(index)}
                        dot={false}
                        unit={" L/s"}
                      />
                    ))}
                    <Tooltip
                      contentStyle={tooltipContentStyle}
                      itemStyle={tooltipItemStyle}
                      content={renderTooltip}
                    />
                    <Legend iconType={"circle"} margin={{ top: 50 }} />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
        </div>
    </>
  );
};

export default AlertCustomerRealTimeUsagePage;
