import { map, tap } from "ramda";
import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { useHistory } from "react-router";
import { isEmpty, propOr } from 'ramda'
import { getImportDashBoard } from "src/main_app/utils/customer_utils";
import { Pager } from '../../components';
import { useUserPermissions, checkPermission, useTenantConfig } from "src/main_app/actions";
import UploadCsv from "src/main_app/components/import-csv";
import { getSignedUrl, getFileTemplate } from "src/main_app/services/apis";
import { saveAs } from 'file-saver';
import ContentWithTooltip from "./content-with-tooltip";
import ImportFilter from "src/main_app/components/import-csv-filter/import-filter";
import { Dropdown, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import axios from 'axios';
import { getApiUrl } from 'src/main_app/utils/config-utility';
import { authService } from 'src/base/services';
import { getDateTimeStringForNameFileImport } from "src/main_app/utils/common";
import { v4 as uuidv4 } from 'uuid';

const DataAlertImport = () => {
  const [dataImportDashBoardList, setDataImportDashBoardList] = useState([]);
  const filterRef = useRef(null);
  const [filterData, setFilterData] = useState(null);
  const tenantConfig = useTenantConfig();

  const history = useHistory()
  const [total, setTotal] = useState(0)
  const [limit] = useState(20)
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoaded, setIsLoaded] = useState(false)
  const [enableImportDashboard, setEnableImportDashboard] = useState(false);

  const userPermissions = useUserPermissions();
  const canViewThisPage = useMemo(
    () => checkPermission(userPermissions, "import-page", "canView"),
    [userPermissions]
  );
  const canEditThisPage = useMemo(
    () => checkPermission(userPermissions, "import-page", "canEdit"),
    [userPermissions]
  );

  //modal
  const [displayModalUploadCsv, setDisplayModaUploadCsv] = useState(false);
  const showModalUploadCsv = useCallback(
    () => setDisplayModaUploadCsv(true),
    [setDisplayModaUploadCsv]
  );

  const handleUploadCsvClose = useCallback(
    () => setDisplayModaUploadCsv(false),
    [setDisplayModaUploadCsv]
  );

  const onFilterApply = (filteredData) => {
    setFilterData(filteredData)  
  }


  const handleDownloadTemplate = async (type) => {
    // const apiRes = await axios.get(`${getApiUrl()}/import-dashboard/download-template-file?&token=${authService.getAccessToken()}&type=${type}`)
    const date = getDateTimeStringForNameFileImport();
    let fileName = '';
    switch(type) {
      case 'Billing':
        fileName = `Billing_${date}.csv`;
        break;
      case 'Customers':
        fileName = `Customers_${date}.csv`;
        break;
      case 'Metered Usage':
        fileName = `Aquo_meters_${date}.csv`;
        break;
      case 'Realtime usage':
        fileName = `aquo_hunterwater_major_${date}.csv`;
        break;
      case 'WEMPs':
        fileName = `aquo_hunterwater_action_wemps_${date}.csv`;
        break;
      case 'Customer Contact':
        fileName = `aquo_hunterwater_customer_contact_${date}.csv`;
        break;
      case 'Water balance':
        fileName = `Water_balance_${date}.csv`;
        break;
      case 'ANZSIC Code to Alert Threshold':
        fileName = `ANZSIC code_${date}.csv`;
        break;
      default:
        fileName = '';
    }
    if (type == 'ANZSIC Code to Alert Threshold') {
      axios.get(`${getApiUrl()}/import-dashboard/download-template-file?&token=${authService.getAccessToken()}&type=${type}`, {
        responseType: 'blob'
      }).then(
          res => saveAs(res.data, fileName)
      )
    } else {
      const response = await axios.get(
        `${getApiUrl()}/import-dashboard/download-template-file?token=${authService.getAccessToken()}&type=${type}`
      );
      const fileUrl = response.data;
      saveAs(fileUrl, fileName);
      // axios.get(`${getApiUrl()}/import-dashboard/download-template-file?&token=${authService.getAccessToken()}&type=${type}`, {
      //   responseType: 'blob'
      // }).then(response => saveAs(response, fileName)
      // )
    }
  }


  const loadData = async (pageNo, filteredData = null) => {
    const data = filteredData || filterData
    const response = await getImportDashBoard(pageNo, limit, data)
    setDataImportDashBoardList(propOr([], 'data', response))
    setCurrentPage(pageNo)
    setTotal(propOr([], 'total', response))
  }

  const gotoDetail = alertImportId => {
    history.push(`/alert-import/${alertImportId}`)
  }

  const downloadFileS3 = async (item) => {
    const response = await getSignedUrl(item.id, item.link);
    saveAs(response.data, item.name_file)
  }

  useEffect(() => {
    if (!canViewThisPage) {
      alert("You don't have permission to view this page");
      document.location.href = "/";
    }
  }, [canViewThisPage]);

  useEffect(() => {
    if (isEmpty(tenantConfig)) return;
    setEnableImportDashboard(tenantConfig.importDashboard.enable)
    loadData(1).then(() => setIsLoaded(true))
  }, [filterData, tenantConfig])

  return userPermissions.length === 0 || !canViewThisPage ? null : (
    <div className="the-content">
      <div className="page-heading ">
        <div className="container-fluid block-center-between">
          <h2 className="title mb-0">Import Dashboard</h2>
          {enableImportDashboard == true && (
            <div className="customer-nav-right block-center">
            {canEditThisPage && (
              <button className="btn-text f-14 secondary-color" onClick={showModalUploadCsv}>
                <i className="far fa-plus-circle mr-2" />
                Upload data
              </button>
              )}
              {/* Start download template */}
              <span className="alert-ellipsis alert-float-right ml-3">
                <Dropdown>
                  <div onClick={(e) => e.stopPropagation()}>
                    <Dropdown.Toggle variant="" className="btn-text f-14 secondary-color">
                      Download template
                      <i style={{width:"22px"}} className="fas fa-angle-down" />
                    </Dropdown.Toggle>
                  </div>
                  <Dropdown.Menu align="right">
                    {(tenantConfig.importDashboard.listDownloadTemlate || []).map( type => 
                      <Dropdown.Item key={uuidv4()} as={Button} onClick={event => handleDownloadTemplate(type)}>{type}</Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </span>
              {/* End download template */}
            </div>
          )}
        </div>
      </div>
      <div className="container-fluid content">
      <div className="block-center" style={{paddingBottom: "20px"}}>
            <ImportFilter
              ref={filterRef}
              onFiltersApply={onFilterApply}
            />
          </div>
        <>
            <div className="table-responsive">
            <table className="table" id="user-list-table">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Import type</th>
                        <th>Source</th>
                        <th>Status</th>
                        <th>Added/ Modified records</th>
                        <th>Author</th>
                        <th></th>
                        <th>Download file</th>
                    </tr>
                </thead>
                <tbody>
                {dataImportDashBoardList.map((item, index) => (
                    <tr key={index}>
                        <td className={item.status == 'Failed' ? "statusError" : item.status == 'Pending' ? "statusPending" : "statusSuccess"} onClick={event => gotoDetail(item.id)}>{item.created_at}</td>
                        <td onClick={event => gotoDetail(item.id)}>{item.type}</td>
                        <td onClick={event => gotoDetail(item.id)}>{item.link} - {item.name_file}</td>
                        <td onClick={event => gotoDetail(item.id)}>{item.status}</td>
                        <td onClick={event => gotoDetail(item.id)}>{item.count_new_customer} added{item.count_updated_customer > 0 ? `, ${item.count_updated_customer} updated` : ''}</td>
                        <td onClick={event => gotoDetail(item.id)}>
                          {item.user_id ? `${item.first_name} ${item.last_name}` : 'System'}
                        </td>
                        <td style={{textAlign: "right", paddingRight: "0"}}>
                          {item.status == 'Failed' ? <ContentWithTooltip cid={item.id} type="name"></ContentWithTooltip> : '' }
                        </td>
                        <td>
                          <a className="underline" onClick={event => downloadFileS3(item)}> Download</a>
                        </td>
                    </tr>
                ))}
                </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-lg-end pagination-box">
            <Pager
              onPageChange={loadData}
              total={total}
              limit={limit}
              currentPage={currentPage}
            />
          </div>
          <UploadCsv
            tenantConfig={tenantConfig}
            showModal={displayModalUploadCsv}
            handleActionsClose={handleUploadCsvClose}
            onUploaded={loadData}
          />
        </>
      </div>
    </div>
  );
};

export default DataAlertImport;


