import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import { andThen, propOr, compose } from "ramda";
import { useParams, Route } from "react-router-dom";

import CustomerNav from "../../components/customer-nav/customer-nav";
import CustomerRightMenu from "../../components/customer-right-menu";
import { getCustomerData } from "../../utils/customer_utils";

import { track } from "src/main_app/mixpanel";
import { useUserPermissions, checkPermission } from "src/main_app/actions";
import { useTenantConfig } from "src/main_app/actions/tenant";
import { getCustomersDataLogin } from "src/main_app/services/apis";
import { authService } from "../../../base/services";
import { getTenantInfoFromLocal } from "src/main_app/services/local_storage/tenant-info";
import { name } from "@azure/msal-browser/dist/packageMetadata";

const CustomerDetailPage = (props) => {
  const tenantInfo = useRef(getTenantInfoFromLocal());
  useEffect(() => {
    track("Customer detail page", {
      tenant_id: tenantInfo.current.id,
      tenant_name: tenantInfo.current.name,
    });
  }, []);
  const tenantConfig = useTenantConfig();
  const params = useParams();
  const [customerName, setCustomerName] = useState("");
  const [customerObj, setCustomerObj] = useState({});
  const user = authService.getUser();

  const userPermissions = useUserPermissions();
  const canViewThisPage = useMemo(
    () => checkPermission(userPermissions, "customers-page", "canView"),
    [userPermissions]
  );
  const canEditThisPage = useMemo(
    () => checkPermission(userPermissions, "customers-page", "canEdit"),
    [userPermissions]
  );
  useEffect(() => {
    if (!canViewThisPage) {
      alert("You don't have permission to view this page");
      document.location.href = "/";
    }
  }, [canViewThisPage]);

  const loadData = useCallback(
    // compose(
    //   andThen(setCustomerName),
    //   andThen(propOr("", "name")),
    //   getCustomerData
    // ),
    compose(
      andThen((data) => {
        setCustomerName(propOr("", "name", data));
        setCustomerObj(data);
      }),
      getCustomerData
    ),
    []
  );

  const customerLogin = async (data) => {
    const response = await getCustomersDataLogin(data)
    const customerId = propOr([], 'data', response);
    if (customerId != params.id) {
      document.location.href = `customer/${customerId}/overview`;
    }
  }

  const nameOfCustomer = (customerObj) => {
    let name = '';
    if (customerObj.suburb_name) {
      name = customerObj.address + ", " + customerObj.suburb_name +" Installation ID " + customerObj.installation_id 
    } 
    return name
  }   

  useEffect(() => {
    loadData(params.id);
  }, []);

  useEffect(() => {
    if (user.role == 'Customer') {
      customerLogin(user);
    }
  }, [user]);
  const { routes = [] } = props;

  return userPermissions.length === 0 || !canViewThisPage ? null : (
    <div className="the-content">
      <div className="page-heading pb-0">
        <div className="container-fluid ">
          <h2 className="title mb-0">
            {customerName !== "" ? customerName : nameOfCustomer(customerObj)}
            {/* {customerName !== "" ? customerName : <Skeleton />} */}
          </h2>
          <div className="block-center-between">
            <div className="left-action-menu">
              <CustomerNav customerId={params.id} />
            </div>
            {canEditThisPage && tenantConfig.slug != 'maitland' && (
              <div className="right-action-menu">
                <CustomerRightMenu tenantConfig={tenantConfig} />
              </div>
            )}
          </div>
        </div>
      </div>
      {routes.map(({ component: ComponentPage, routes, path, ...rest }, i) => (
        <Route
          key={i + ""}
          path={`${props.match.path}${path}`}
          {...rest}
          render={(routeProps) => (
            <ComponentPage
              routes={routes}
              {...routeProps}
              reloadInfo={loadData}
              customerName={customerName}
            />
          )}
        />
      ))}
    </div>
  );
};

export default CustomerDetailPage;
