import React from 'react'
import {Tooltip} from 'react-bootstrap'

const renderTooltipAlert = (alertType) => {
    let content = '';
    switch (alertType) {
      case 'base_flow':
        content = 'Base flow';
        break;
      case 'night_flow':
        content = 'Constant usage between 2 - 4am';
        break;
      case 'leak_flow':
        content = 'Flow rate of greater than 16L/h over a 24H period';
        break;
      case 'data_loss':
        content = 'No data received in 24H period';
        break;
      case 'peak_flow':
        content = 'Usage of over 4L/s at any time in a 24hr period';
        break;
      case 'step_change':
        content = 'Total usage in 7 days is greater or less than 30% of previous average weekly usage, based on data for previous 4 weeks';
        break;
      case 'signal_strength':
        content = 'ECL mode 2 observed in past 7 days';
        break;
      case 'water_quality':
        content = 'Water quality';
        break;
      case 'bill_spike':
        content = 'Increase in usage of more than 30% based on average usage over the  past four weeks';
        break;
      case 'no_usage':
        content = 'No usage for more than 30 days';
        break;
      case 'all':
        content = 'Sum of all alert types';
        break;
    }
    return (
      <Tooltip id={`tooltip`}>
        {
          content
        }
      </Tooltip>
    );
  }

export default renderTooltipAlert