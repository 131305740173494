import React, { useEffect, useState } from "react";
import { SignInBaseForm, SignInMultiOptions } from "../components/user";
import { useToasts } from "react-toast-notifications";
import { authService } from "../../base/services";
import { isNilOrEmpty } from "src/main_app/utils/common";
import queryString from "query-string";

import { track, identifyUser } from "src/main_app/mixpanel";

import("./sign-in-style.scss");

export const SignInContext = React.createContext();

const SignInPage = (props) => {
  const [loading, setLoading] = useState(false);
  const [afterLogin, setAfterLogin] = useState({ status: "idle" });
  const { addToast } = useToasts();

  useEffect(() => {
    track("SignIn page");
  }, []);

  useEffect(() => {
    if (afterLogin?.status === "ok") {
      addToast("Login successfully", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });

      authService.authenticate({
        account: afterLogin.data.result.user,
        accessToken: afterLogin.data.result.token,
        tenant_info: afterLogin.data.result.tenant_info,
      });

      identifyUser();

      const { url = "/" } = queryString.parse(location.search);
      const customer_id = afterLogin.data.customer;

      setTimeout(() => {
        document.location.href = 
          props.tenantConfig.pathLandingPage 
          ? props.tenantConfig.pathLandingPage 
          : afterLogin.data.customer && props.tenantConfig.slug !== "intellihub"
          ? `customer/${customer_id}/overview`
           : afterLogin.data.customer && props.tenantConfig.slug === "intellihub"
          ? `customer/${customer_id}/usage`
          : isNilOrEmpty(url) || /undefined/.test(url)
          ? "/"
          : url;
      }, 1500);
    }

    if (afterLogin?.status === "failed") {
      addToast(`Login failed. ${afterLogin?.message}`, {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 10000,
      });
    }
  }, [afterLogin]);

  const defaultContextValue = {
    loading,
    afterLogin,
    setLoading,
    setAfterLogin,
  };

  return (
    <div className="col-sm-6 sign-in__body">
      <h1>Sign in to your account</h1>
      {loading && <div className="sign-in__loading-overlay"></div>}
      <SignInContext.Provider value={defaultContextValue}>
        {props.tenantConfig?.loginOptions && (
          <SignInMultiOptions tenantConfig={props.tenantConfig} />
        )}
      </SignInContext.Provider>
    </div>
  );
};

export default SignInPage;
