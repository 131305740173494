import moment from "moment";
import React, { useEffect, useRef, useMemo, useState } from "react";
import { useParams } from "react-router";
import { getCustomerWaterUsage, getCustomersDetail } from "src/main_app/services/apis";
import { map, last, isEmpty, isNil } from "ramda";
import { customerHasSumsData } from "src/main_app/services/apis";

import getConsumptionData from "../../../../utils/customer_utils/get-consumption-data";
import getConsumptionBillingData from "../../../../utils/customer_utils/get-consumption-billing-data";
import {
  addDays,
  addMonths,
  convertDate,
  convertDateWithShotMonthName,
  firstDateOfMonthsAgo,
  roundNumber,
  isNotNil,
} from "../../../../utils/common";

import CustomersUsageSkeleton from "./customers-usage-skeleton";
import CustomersUsagePage from "./customers-usage-page";

import { track } from "src/main_app/mixpanel";
import { authService } from "src/base/services";
import { getApiUrl } from 'src/main_app/utils/config-utility';
import axios from 'axios';
import getConsumptionQuarterlyData from "src/main_app/utils/customer_utils/get-consumption-quarterly-data";

export default (props) => {
  useEffect(() => {
    track("Customer usage page");
  }, []);
  const ticksRef = useRef([]);
  const params = useParams();
  const [customer, setCustomer] = useState(null);

  const [hasRealtimeData, setHasRealtimeData] = useState(false);
  const [dataWeekly, setDataWeekly] = useState(null);
  const [meterNames, setMeterNames] = useState([]);
  const [usageData, setUsageData] = useState([]);
  const [billingData, setBillingData] = useState([]);

  const [dataQuarterly, setDataQuarterly] = useState([]);
  const [meterNamesQuarterly, setMeterNamesQuarterly] = useState([]);
  // const [dataLast3Months, setDataLast3Months] = useState([]);

  const [fromDateSumsData, setFromDateSumsData] = useState(null)
  const [toDateSumsData, setToDateSumsData] = useState(null)
  const [toDateCsvFile, setToDateCsvFile] = useState(null)
  const [fromDateCsvFile, setFromDateCsvFile] = useState(null)

  const [skeleton, setSkeleton] = useState(true);

  const dateRangeDataWeekly = useMemo(() => {
    const today = new Date();
    const toDate = addDays(today, -1);
    const fromDate = addDays(today, -8);
    return [fromDate, toDate];
  }, []);

  const dateRangeQuarterly = useMemo(() => {
    const today = new Date();
    const toDate = addDays(today, -1);
    const fromDate = addMonths(today, -3);
    return [fromDate, toDate];
  }, []);

  const getDataWeekly = async (from, to) => {
    const ticks = []
    const {
      data: { data },
    } = await getCustomerWaterUsage(params.id, {
      from: convertDate(from || dateRangeDataWeekly[0]),
      to: convertDate(to || dateRangeDataWeekly[1]),
    });

    if (!isNil(from)) {
      setFromDateSumsData(moment(from).utc().format("YYYY-MM-DD HH:mm:ss"));
      setToDateSumsData(moment(to).utc().format("YYYY-MM-DD HH:mm:ss"));
      setToDateCsvFile(moment(to).utc().format("YYYY-MM-DD"));
      setFromDateCsvFile(moment(from).utc().format("YYYY-MM-DD"));
    } else {
      const formatToDate = convertDate(dateRangeDataWeekly[1]) + ' 23:59:00';
      setFromDateSumsData(convertDate(dateRangeDataWeekly[0]));
      setToDateSumsData(formatToDate);
      setToDateCsvFile(convertDate(dateRangeDataWeekly[1]));
      setFromDateCsvFile(convertDate(dateRangeDataWeekly[0]));
    }
    
    const allMeterNames = [];

    let arrDataWeekly = map((item) => {
      const { time, ...metersData } = item;

      if (isEmpty(metersData)) return { time };

      const utcTime = moment(time).utcOffset(0, true);

      const utcUnix = utcTime.unix();

      if (
        isEmpty(ticks) ||
        utcUnix - last(ticks) >= 86400
      ) {
        ticks.push(utcUnix);
      }

      const combinedData = {
        date: utcUnix,
        displayDate: utcTime.format("ddd MMM DD hh:mm a"),
      };

      for (const meterName in metersData) {
        if (!Object.hasOwnProperty.call(metersData, meterName)) continue;
        allMeterNames.push(meterName);
        const sumUsage = metersData[meterName];
        combinedData[meterName] = sumUsage
          ? Math.round(sumUsage * 100) / 100
          // ? Math.round(sumUsage * 10) / 10
          : null;
      }

      ticksRef.current  = ticks

      return combinedData;
    }, data);

    const setOfMeterNames = [...new Set(allMeterNames)];
    setMeterNames(setOfMeterNames);

    arrDataWeekly = map((item) => {
      for (const meterName of setOfMeterNames) {
        if (isNotNil(item[meterName])) continue;
        item[meterName] = 0;
      }

      return item;
    }, arrDataWeekly);

    setDataWeekly(arrDataWeekly);
  };


  // const getDataQuarterly = async (from, to) => {
  //   const ticks = []
  //   const {
  //     data: { data },
  //   } = await getCustomerWaterUsage(params.id, {
  //     from: convertDate(from || dateRangeQuarterly[0]),
  //     to: convertDate(to || dateRangeQuarterly[1]),
  //   });
  //   const allMeterNames = [];
  //   let arrDataQuarterly = map((item) => {
  //     const { time, ...metersData } = item;
  //     if (isEmpty(metersData)) return { time };
  //     const utcTime = moment(time).utcOffset(0, true);
  //     const utcUnix = utcTime.unix();
  //     if (
  //       isEmpty(ticks) ||
  //       utcUnix - last(ticks) >= 86400
  //     ) {
  //       ticks.push(utcUnix);
  //     }

  //     const combinedData = {
  //       date: utcUnix,
  //       displayDate: utcTime.format("ddd MMM DD hh:mm a"),
  //     };

  //     for (const meterName in metersData) {
  //       if (!Object.hasOwnProperty.call(metersData, meterName)) continue;
  //       allMeterNames.push(meterName);
  //       const sumUsage = metersData[meterName];
  //       combinedData[meterName] = sumUsage
  //         ? Math.round(sumUsage * 100) / 100
  //         : null;
  //     }

  //     ticksRef.current  = ticks

  //     return combinedData;
  //   }, data);

  //   const setOfMeterNames = [...new Set(allMeterNames)];
  //   setMeterNamesQuarterly(setOfMeterNames);

  //   arrDataQuarterly = map((item) => {
  //     for (const meterName of setOfMeterNames) {
  //       if (isNotNil(item[meterName])) continue;
  //       item[meterName] = 0;
  //     }
  //     return item;
  //   }, arrDataQuarterly);

  //   setDataQuarterly(arrDataQuarterly);
  // };
  

  const dateRangeWaterUsage = useMemo(() => {
    const startDate = firstDateOfMonthsAgo(12);
    const endDate = new Date(new Date().setDate(1));
    return [startDate, endDate];
  }, []);

  const dateRangeBilling = useMemo(() => {
    const startDate = firstDateOfMonthsAgo(12);
    const endDate = new Date(new Date().setDate(1));
    return [startDate, endDate];
  }, []);

  const getUsageData = async (from, to) => {
    const usageConsumption = await getConsumptionData(
      params.id,
      convertDate(from || dateRangeWaterUsage[0]),
      convertDate(to || dateRangeWaterUsage[1])
    );
    setUsageData(
      usageConsumption.map((result) => ({
        usage: roundNumber(result.consumption),
        date: convertDateWithShotMonthName(new Date(result.end_date)),
        startDate: convertDateWithShotMonthName(new Date(result.start_date)),
        consumptions: result.arr_consumption,
        merterSerials: result.arr_meter_serial
      }))
    );
  };

  const getBillingData = async (from, to) => {
    const usageConsumption = await getConsumptionBillingData(
      params.id,
      convertDate(from || dateRangeBilling[0]),
      convertDate(to || dateRangeBilling[1])
    );
    setBillingData(
      usageConsumption.map((result) => ({
        usage: roundNumber(result.consumption),
        date: convertDateWithShotMonthName(new Date(result.end_date)),
        startDate: convertDateWithShotMonthName(new Date(result.start_date)),
        consumptions: result.arr_consumption,
        merterSerials: result.arr_meter_serial
      }))
    );
  };

  const getDataQuarterly = async (from, to) => {
    const usageConsumption = await getConsumptionQuarterlyData(
      params.id,
      convertDate(from || dateRangeQuarterly[0]),
      convertDate(to || dateRangeQuarterly[1])
    );
    console.log('usageConsumption', usageConsumption)
    // console.log('usageConsumption2', 
    //   usageConsumption.map((result) => ({
    //     usage: roundNumber(result.total_consumption),
    //     date: result.start_date,
    //     startDate: result.start_date,
    //     consumptions: result.arr_consumption,
    //     merterSerials: result.arr_meter_serial
    //   })))

    setDataQuarterly(
      usageConsumption.map((result) => ({
        usage: roundNumber(result.total_consumption),
        date: result.month,
        startDate: result.month,
        consumptions: result.arr_consumption,
        merterSerials: result.arr_meter_serial
      }))
    );
  };

  const handleDownloadDataRealTime =  () => {
    // download
      const name = props.customerName;
      axios.get(`${getApiUrl()}/export/sums-data/csv?&token=${authService.getAccessToken()}&from=${fromDateSumsData}&to=${toDateSumsData}&customer_id=${params.id}`, {
        responseType: 'blob'
      }).then(
          res => saveAs(res.data, `${name}_${fromDateCsvFile}_${toDateCsvFile}.csv`)
      )
   }

   const getAddressCustomer = async () => {
      const {data: data} = await getCustomersDetail(params.id);
      const custometObj = data.data;
      setCustomer(custometObj);
   }

  useEffect(() => {
    getBillingData();
    getDataQuarterly();
    getAddressCustomer()
    Promise.all([customerHasSumsData(params.id), getUsageData()]).then(
      ([
        {
          data: { data },
        },
      ]) => {
        // if (data.length > 0) {
        if (data[0].has_sums_data){
          setHasRealtimeData(true);
          getDataWeekly().then(() => setTimeout(() => setSkeleton(false), 200));
          // getDataQuarterly().then(() => setTimeout(() => setSkeleton(false), 200));
        } else {
          setTimeout(() => setSkeleton(false), 200);
        }
      }
    );
  }, []);

  if (skeleton) return <CustomersUsageSkeleton />;

  return (
    <CustomersUsagePage
      hasRealtimeData={hasRealtimeData}
      dateRangeDataWeekly={dateRangeDataWeekly}
      getDataWeekly={getDataWeekly}
      dataWeekly={dataWeekly}
      meterNames={meterNames}
      dateRangeWaterUsage={dateRangeWaterUsage}
      dateRangeBilling={dateRangeBilling}
      getUsageData={getUsageData}
      usageData={usageData}
      getBillingData={getBillingData}
      billingData={billingData}
      handleDownloadDataRealTime={handleDownloadDataRealTime}
      ticks={ticksRef.current}
      dateRangeQuarterly={dateRangeQuarterly}
      getQuarterlyData={getDataQuarterly}
      dataQuarterly={dataQuarterly}
      meterNamesQuarterly={meterNamesQuarterly}
      customer={customer}
    />
  );
};
