import React, { useState, useEffect, useCallback, useMemo, useLayoutEffect, useRef } from "react";
import { andThen, propOr, compose, T, isEmpty, max, min, map, last } from "ramda";
import { track } from "src/main_app/mixpanel";
import { useUserPermissions, checkPermission } from "src/main_app/actions";
import { useTenantConfig } from "src/main_app/actions/tenant";
import { LineChart, Line, AreaChart, Area, XAxis, YAxis, CartesianGrid,
   Tooltip, ResponsiveContainer, Legend, ReferenceLine, ReferenceArea} from 'recharts';
import ReactMapGL, {
  Popup,
  WebMercatorViewport,
  FlyToInterpolator,
  NavigationControl,
} from "react-map-gl";
import moment from "moment";
import { getMapboxAccessKey } from "../../utils/config-utility";
import { useBooleanStage } from "src/main_app/utils/react_utils";
import { DateRangePicker } from "src/main_app/components/date_range_picker";
import { addDays, convertDate, firstDateOfMonthsAgo, isNotNil, randomColor, isNilOrEmpty, getBoundViewPort} from "src/main_app/utils/common";
import AsyncSelect from '@bhunjadi/react-select-v3/async';
import { waterZoneList,
  deviceAlerts,
  usageAlerts,
  listDays } from "src/main_app/utils/constant-data";
import Paginate from "./paginate";
import { getDataNoneRevenueWaterByZone, getDataResponsiveMeter, getDataUsageAlert, getDeviceAlertPointMap, 
  getDeviceStats, getHighUsageCustomer, getUsageAlertPointMap, getUsageDataHeatMap, getTopFourNightFlowCustomer } from "src/main_app/services/apis";
import MapClusters from "src/main_app/components/map/map-clusters";
import MarkerInfo from "src/main_app/components/map/map_markers_info/marker-info";
import { layerNames } from "src/main_app/components/map/layers/layer-config";
import { getCustomersListData } from "../../utils/customer_utils";
import { Link } from "react-router-dom";
import ContentWithTooltip from "./content-with-tooltip";
import { renderTooltipAlert } from "src/main_app/components";
import { OverlayTrigger } from "react-bootstrap";

const mapboxAccessKey = getMapboxAccessKey();
const linkStyle = { color: "#000" };

const DashBoardPage = (props) => {
  useEffect(() => {
    track("dashboard page");
  }, []);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isDeviceMapFirstLoad, setIsDeviceMapFirstLoad] = useState(true);
  const [isMapLoaded, setIsMapLoaded] = useState(false)
  const [isDeviceMapLoaded, setIsDeviceMapLoaded] = useState(false)
  const bounds = [146.58956046431035, -35.679798358142634, 155.8206598445551, -31.913704963977082]
  const mapRef = useRef(null);
  const mapDeviceRef = useRef(null);
  const tenantConfig = useTenantConfig();
  const [highUsageCustomers, setHighUsageCustomers] = useState([]);
  const [top4NightFlowCustomers, setTop4NightFlowCustomers] = useState([]);
  const [deviceStats, setDeviceStats] = useState({
    negative88: 0,
    negative98: 0,
    negative111: 0,
    negative112: 0,
    total: 0,
  });

  const [numberDevicePoorSignal, setNumberDevicePoorSignal] = useState(0);

  const [totalMeter, setTotalMeter] = useState(0);
  const [activeUsageIndex, setActiveUsageIndex] = useState(0);
  const [activeDeviceIndex, setActiveDeviceIndex] = useState(0);
  const [activeDeviceType, setActiveDeviceType] = useState('poor_signal_strength');

  const [showUsagePicker, setShowUsagePicker] = useState(false);
  const [showWaterUsagePicker, setShowWaterUsagePicker] = useState(false);
  const [showPercentMeterPicker, setShowPercentMeterPicker] = useState(false);
  const [showPercentDeliveryPicker, setShowPercentDeliveryPicker] = useState(false);
  const [showNightFlowPicker, setShowNightFlowPicker] = useState(false);

  const [isOpenWaterUsage, setIsOpenWaterUsage] = useState(false);
  const [isOpenUsageCustomer, setIsOpenUsageCustomer] = useState(false);
  const [isOpenPercentMeter, setIsOpenPercentMeter] = useState(false);
  const [isOpenPercentDelivery, setIsOpenPercentDelivery] = useState(false);
  const [isOpenNightFlow, setIsOpenNightFlow] = useState(false);
  
  const [dayOfWaterUsage, setDayOfWaterUsage] = useState('Last 7 days');
  const [dayOfUsageCustomer, setDayOfUsageCustomer] = useState('Last 7 days');
  const [dayOfPercentMeter, setDayOfPercentMeter] = useState('Last 7 days');
  const [dayOfPercentDelivery, setDayOfPercentDelivery] = useState('Last 7 days');
  const [dayOfNightFlow, setDayOfNightFlow] = useState('Last 7 days');

  const [totalAlertsData, setTotalAlertsData] = useState([]);

  const [dataPercentMeter, setDataPercentMeter] = useState([]);
  const [dataPercentDelivery, setDataPercentDelivery] = useState([]);

  const [isOpenDevice, setIsOpenDevice] = useState(false);
  const [dayOfDevice, setDayOfDevice] = useState(7);

  const [usagePercentMeter, setUsagePercentMeter] = useState(0);
  const [deliveryPercent, setDeliveryPercent] = useState(0);

  //Data for Non-revenue water
  const ticksRef = useRef([]);
  const [dataNonRevenue, setDataNonRevenue] = useState(null);
  const [meterNames, setMeterNames] = useState([]);

  const [namePopupInfo, setNamePopupInfo] = useState(null);
  const [popupInfo, setPopupInfo] = useState(null);

  const [nameAlertPopupInfo, setNameAlertPopupInfo] = useState(null);
  const [alertPopupInfo, setAlertPopupInfo] = useState(null);

  const [deviceAlertPoints, setDeviceAlertPoints] = useState([]);
  const [usageAlertPoints, setUsageAlertPoints] = useState([]);
  const [readyForRenderClusterLayers, setReadyForRenderClusterLayers] = useState(false);
  const [readyForRenderClusterLayersMaps, setReadyForRenderClusterLayersMaps] = useState(false);

  const [layersVisible, setLayersVisible] = useState({
    [layerNames.customers]: true,
  });

  const userPermissions = useUserPermissions();
  const canViewThisPage = useMemo(() => checkPermission(userPermissions, "dashboard-page", "canView"), [userPermissions]) 
  useEffect(() => {
    if (!canViewThisPage) {
      // alert("You don't have permission to view this page");
      // document.location.href = "/map";
    }
  }, [canViewThisPage]);
  
  const mapDataSums = async (date) => {
    const ticks = []
    const allMeterNames = [];
    const { data: { data } } = await getDataNoneRevenueWaterByZone(date)
  
    let arrDataNonRevenue = map((item) => {
      const { date, ...metersData } = item;
  
      if (isEmpty(metersData)) return { date };
  
      const utcTime = moment(date).utcOffset(0, true);
  
      const utcUnix = utcTime.unix();
  
      if (
        isEmpty(ticks) ||
        utcUnix - last(ticks) >= 86400
      ) {
        ticks.push(utcUnix);
      }
  
      const combinedData = {
        date: utcUnix,
        displayDate: utcTime.format("MMM DD"),
      };
  
      for (const meterName in metersData) {
        if (!Object.hasOwnProperty.call(metersData, meterName)) continue;
        allMeterNames.push(meterName);
        const sumUsage = metersData[meterName];
        combinedData[meterName] = sumUsage
          ? Math.round(sumUsage * 10) / 10
          : null;
      }
  
      ticksRef.current  = ticks
  
      return combinedData;
    }, data);
  
    const setOfMeterNames = [...new Set(allMeterNames)];
    arrDataNonRevenue = map((item) => {
      for (const meterName of setOfMeterNames) {
        if (isNotNil(item[meterName])) continue;
        item[meterName] = 0;
      }
  
      return item;
    }, arrDataNonRevenue);
  
    return {setOfMeterNames, arrDataNonRevenue}
  }

  const toggleDropdownUsageCustomer = () => {
    setIsOpenUsageCustomer(!isOpenUsageCustomer);
  };

  const toggleDropdownNightFlow = () => {
    setIsOpenNightFlow(!isOpenNightFlow);
  };

  const toggleDropdownWaterUsage = () => {
    setIsOpenWaterUsage(!isOpenWaterUsage);
  };

  const toggleDropdownDevice = () => {
    setIsOpenDevice(!isOpenDevice);
  };

  const toggleDropdownPercentMeter = () => {
    setIsOpenPercentMeter(!isOpenPercentMeter);
  };
  
  const toggleDropdownPercentDelivery = () => {
    setIsOpenPercentDelivery(!isOpenPercentDelivery);
  };

  const exportUsageDataMap = () => {}
  const exportDeviceDataMap = () => {}
  
  const normalViewport = {
    mapStyle: "mapbox://styles/loweblue0510/ckkqoydb526lt17s1s5ql84of",
    pitch: undefined,
  };
  const defaultPosition = {
    longitude: 151.20511015442966,
    latitude: -33.84472358322997,
    zoom: 10,
  };

  const defaultViewPort = {
    width: "100%",
    height: "55vh",
    ...defaultPosition,
    ...(normalViewport || {}),
  }

  const [viewport, setViewport] = useState(defaultViewPort);
  const [deviceViewport, setDeviceViewport] = useState(defaultViewPort);

  const handleUsageClick = (index, title, type) => {
    setActiveUsageIndex(index);
    fetchDataUsageAlertPoints(type)
  };

  const handleDeviceClick = (index, type) => {
    setActiveDeviceIndex(index);
    // setActiveDeviceType(type)
    fetchDataDeviceAlertPointMap(type)
  };

  const fetchDataDeviceAlertPointMap = async (typeUsage) => {
    const type = typeUsage ? typeUsage : "poor_signal_strength";
    const { data: { data },} = await getDeviceAlertPointMap(type, null);
    if (type === "poor_signal_strength") {
      setNumberDevicePoorSignal(data.length)
    }

    const mapData = (data||[]).map((item) => {
      return {
        ...item,
        latitude: Number(item.latitude),
        longitude: Number(item.longitude),
      };
    });
    if (data?.length === 0 || data === null) {
      setDeviceAlertPoints([])
    } else {
      setDeviceAlertPoints(mapData)
    }
  };

  const CustomLegend = () => (
    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", padding: "10px 0" }}>
        <span
          style={{
            width: "15px",
            height: "15px",
            backgroundColor: "#d8e6d8",
            display: "inline-block",
            borderRadius: "50%",
            marginRight: "5px"
          }}
        ></span>
        <span className="mr-3">Target percentage</span>
    </div>
  );

const loadDataDevice = async () => {
  const dataDevice = await getDeviceStats(dayOfDevice)
  const dataRealTime = await mapDataSums(dayOfPercentDelivery);
  const dataUsageAlert = await getDataUsageAlert()
  setTotalAlertsData(dataUsageAlert['data'])
  setDataNonRevenue(dataRealTime['arrDataNonRevenue']);
  setMeterNames(dataRealTime['setOfMeterNames']);
  setDeviceStats(dataDevice['data'])

  const { total } = await getCustomersListData(1, 10, null, null);
  setTotalMeter(total);
}

const loadDataHightUsage = async () => {
  const { data: { data } } = await getHighUsageCustomer(dayOfUsageCustomer)
  const dataTopFour = await getTopFourNightFlowCustomer(dayOfNightFlow)
  setHighUsageCustomers(data)
  setTop4NightFlowCustomers(dataTopFour['data']['data'])
}

const loadDataMeter = async () => {
  const devicePercentMeter = await getDataResponsiveMeter("Last 7 days")
  const dataPercentMeter = await getDataResponsiveMeter(dayOfPercentMeter)
  // const dataPercentDelivery = await getDataResponsiveMeter(dayOfPercentDelivery)
  setDataPercentMeter(dataPercentMeter['data']['data'])
  // setDataPercentDelivery(dataPercentDelivery['data']['data'])
  setUsagePercentMeter(devicePercentMeter['data']['deviceStatsResMeter'])

  const maxPercentData = (devicePercentMeter['data']['data'] || []).reduce((max, item) => 
    parseFloat(item.Percent) > parseFloat(max.Percent) ? item : max
  );
  setDeliveryPercent(maxPercentData['Percent'])
}

const onChangeWaterUsage = async (day) => {
  // const { data: { data } } = await getHighUsageCustomer(day, {
  //   from: convertDate(dateRangeWaterUsage[0]),
  //   to: convertDate(dateRangeWaterUsage[1]),
  // })
  if (day === "Custom date range") {
    setShowWaterUsagePicker(true);
  } else {
    setShowWaterUsagePicker(false);
  }
  setDayOfWaterUsage(day)
}

const onChangeHighUsageCustomer = async (day) => {
  const { data: { data } } = await getHighUsageCustomer(day, {
    from: convertDate(dateRangeWaterUsage[0]),
    to: convertDate(dateRangeWaterUsage[1]),
  })
  if (day === "Custom date range") {
    setShowUsagePicker(true);
  } else {
    setShowUsagePicker(false);
  }
  setHighUsageCustomers(data)
  setDayOfUsageCustomer(day)
}

const onChangeNightFlow = async (day) => {
  const { data: { data } } = await getTopFourNightFlowCustomer(day, {
    from: convertDate(dateRangeNightFlow[0]),
    to: convertDate(dateRangeNightFlow[1]),
  })
  if (day === "Custom date range") {
    setShowNightFlowPicker(true);
  } else {
    setShowNightFlowPicker(false);
  }
  setTop4NightFlowCustomers(data)
  setDayOfNightFlow(day)
}

const onChangePercentMeter = async (day) => {
  if (day === "Custom date range") {
    setShowPercentMeterPicker(true);
  } else {
    setShowPercentMeterPicker(false);
    const dataPercentMeter = await getDataResponsiveMeter(day)
    setDataPercentMeter(dataPercentMeter['data']['data'])
  }
  setDayOfPercentMeter(day)
}


const onChangePercentDelivery = async (day) => {
  if (day === "Custom date range") {
    setShowPercentDeliveryPicker(true);
  } else {
    setShowPercentDeliveryPicker(false);
    const dataPercentDelivery = await getDataResponsiveMeter(day)
    setDataPercentDelivery(dataPercentDelivery['data']['data'])
  }
  setDayOfPercentDelivery(day)
}

const onChangeDeviceStats = async (day) => {
  const dataDevice = await getDeviceStats(day)
  setDeviceStats(dataDevice['data'])
  setDayOfDevice(day)
}

const formatDataML = (value) => {
  return (Math.round(value * 100) / 100).toLocaleString();
}

const onApplyHightUsageCustomer = async (event, picker) => {
    event.stopPropagation();
    const fromDate = picker.startDate.utcOffset(0, true).toDate()
    const toDate = picker.endDate.utcOffset(0, true).toDate()

    const { data: { data } } = await getHighUsageCustomer(dayOfUsageCustomer, {
      from: convertDate(fromDate),
      to: convertDate(toDate),
    })
    setHighUsageCustomers(data)
}

const onApplyNightFlow = async (event, picker) => {
    event.stopPropagation();
    const fromDate = picker.startDate.utcOffset(0, true).toDate()
    const toDate = picker.endDate.utcOffset(0, true).toDate()

    const { data: { data } } = await getTopFourNightFlowCustomer(dayOfNightFlow, {
      from: convertDate(fromDate),
      to: convertDate(toDate),
    })
    setTop4NightFlowCustomers(data)
}

const onApplyWaterUsageChart = async (event, picker) => {
  event.stopPropagation();
  const fromDate = picker.startDate.utcOffset(0, true).toDate()
  const toDate = picker.endDate.utcOffset(0, true).toDate()
}

const onApplyPercentMeterChart = async (event, picker) => {
  event.stopPropagation();
  const fromDate = picker.startDate.utcOffset(0, true).toDate()
  const toDate = picker.endDate.utcOffset(0, true).toDate()

  const { data: { data } } = await getDataResponsiveMeter(dayOfPercentMeter, {
    from: convertDate(fromDate),
    to: convertDate(toDate),
  })
  
  setDataPercentMeter(data)
 
}


const onApplyPercentDeliveryChart = async (event, picker) => {
  event.stopPropagation();
  const fromDate = picker.startDate.utcOffset(0, true).toDate()
  const toDate = picker.endDate.utcOffset(0, true).toDate()

  const { data: { data } } = await getDataResponsiveMeter(dayOfPercentDelivery, {
    from: convertDate(fromDate),
    to: convertDate(toDate),
  })
  
  setDataPercentDelivery(data)
}

// Target range
const targetRangeStart = 8;
const targetRangeEnd = 12;

  useEffect(() => {
    loadDataDevice();
    loadDataHightUsage();
    loadDataMeter();

    fetchDataDeviceAlertPointMap(null);
  }, []);

  const fetchDataUsageAlertPoints = async (typeUsage) => {
    try {
      const type = typeUsage ? typeUsage : usageAlerts[0].type;
      const { data: { data },} = await getUsageAlertPointMap(type, null);
      
      const mapData = data.map((item) => {
        return {
          ...item,
          latitude: Number(item.latitude),
          longitude: Number(item.longitude),
        };
      });
      setUsageAlertPoints(mapData)
     
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchDataUsageAlertPoints()
  }, [])

  const dateRangeWaterUsage = useMemo(() => {
    const today = new Date();
    const toDate = addDays(today, -1);
    const fromDate = addDays(today, -8);
    return [fromDate, toDate];
  }, []);

  const dateRangeNightFlow = useMemo(() => {
    const today = new Date();
    const toDate = addDays(today, -1);
    const fromDate = addDays(today, -8);
    return [fromDate, toDate];
  }, []);

  const handleChildClick = (e) => {
    e.stopPropagation(); // Prevent the event from propagating to the parent div
    setIsOpenUsageCustomer(false);
  };

  const handleChildWaterUsageClick = (e) => {
    e.stopPropagation(); // Prevent the event from propagating to the parent div
    setIsOpenWaterUsage(false);
  };

  const handleChildPercentMeterClick = (e) => {
    e.stopPropagation(); // Prevent the event from propagating to the parent div
    setIsOpenPercentMeter(false);
  };

  const handleChildPercentDeliveryClick = (e) => {
    e.stopPropagation(); // Prevent the event from propagating to the parent div
    setIsOpenPercentMeter(false);
  };

  useLayoutEffect(() => {
    import("./style.scss");
  }, []);

  const clearInfoWindow = () => {
    if (isNilOrEmpty(popupInfo) || layersVisible[popupInfo.type]) return;

    setPopupInfo(null);
  };

  const onDeviceFitBounds = (data) => {
    const bounds = getBoundViewPort(data);
    if (data.length === 0) return;
  
    console.log("bounds", bounds);
    // Compute new viewport
    const { longitude, latitude, zoom } = new WebMercatorViewport(
        deviceViewport
    ).fitBounds(bounds);

      setDeviceViewport({
        ...deviceViewport,
        // height: window.innerHeight -
        // document.querySelector(".app__header").clientHeight,
        // width: window.innerWidth,
        longitude,
        latitude,
        zoom: zoom - 1,
        transitionInterpolator: new FlyToInterpolator({
          speed: 3,
        }),
        transitionDuration: "auto",
      });
  };

  const onAlertFitBounds = (data) => {
    const bounds = getBoundViewPort(data);
    if (data.length === 0) return;
  
    console.log("bounds", bounds);
    // Compute new viewport
    const { longitude, latitude, zoom } = new WebMercatorViewport(
        viewport
    ).fitBounds(bounds);


      setViewport({
        ...viewport,
        // height: window.innerHeight -
        // document.querySelector(".app__header").clientHeight,
        // width: window.innerWidth,
        longitude,
        latitude,
        zoom: zoom - 1,
        transitionInterpolator: new FlyToInterpolator({
          speed: 3,
        }),
        transitionDuration: "auto",
      });
  };

  useLayoutEffect(() => {
    if (!tenantConfig.defaultPosition) return;
    
    setViewport({ ...viewport, ...tenantConfig.defaultPosition });
    setDeviceViewport({ ...deviceViewport, ...tenantConfig.defaultPosition });
  }, [tenantConfig]);


  const clearNamePopupInfo = () => setNamePopupInfo(null);
  const clearNameAlertPopupInfo = () => setNameAlertPopupInfo(null);

  const onMarkerClick = compose(clearNamePopupInfo, setPopupInfo);
  const onMarkerAlertClick = compose(clearNameAlertPopupInfo, setAlertPopupInfo);

  const UsageAlerts = (totalAlertsData) => (
    <>
      {usageAlerts.map((item, index) => {
          // Find the corresponding alert based on type
          const alertItem = totalAlertsData?.find(alert => alert.type === item.type);
          const displayValue = alertItem ? alertItem.unique_customers : item.value;
        
          return (
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltipAlert(item.type)}
            >
              <div 
                key={index}  
                className={`row cursor mt-3 ${activeUsageIndex == index ? 'active-item-full-w' : 'alert'}`}
                onClick={() => handleUsageClick(index, item.title, item.type)}
              >
                <div className="col-md-2 ticket-total-alert min-h-alert">
                  <p>{displayValue}</p>
                </div>
                <div className={`col-md-10 pr-0 min-h-alert title-alert ${activeUsageIndex == index ? 'active-d' : ''}`} >
                  {item.title}
                </div>
              </div>
            </OverlayTrigger>
          );
        })}
      </>
    )
      
  const getCustomerName = (item) => {
    const name = item.name === "" ? `${item.address}, Installation ID ${item.uuid}` : item.name;
    return name;
  }

  useLayoutEffect(() => {
    clearInfoWindow();
  }, [
    layersVisible[layerNames.customers],
  ]);

  ///Usage map alert points
  useEffect(() => {
    if (mapRef.current && isFirstLoad) {
      setTimeout(() => {
        const mapBounds = mapRef.current.getMap().getBounds().toArray().flat();
        setReadyForRenderClusterLayersMaps(bounds);
        if (mapBounds) {
          setIsFirstLoad(false);
        }
      }, 3000);
    }
  }, [viewport]);

  useEffect(() => {
    if (usageAlertPoints.length > 0 && isMapLoaded && mapRef.current) {
      onAlertFitBounds(usageAlertPoints);
    }
  }, [usageAlertPoints, isMapLoaded]);

  //Device map alert points
  useEffect(() => {
    if (mapDeviceRef.current && isDeviceMapFirstLoad) {
      setTimeout(() => {
        const mapBounds = mapDeviceRef.current.getMap().getBounds().toArray().flat();
        setReadyForRenderClusterLayers(bounds);
        if (mapBounds) {
          setIsDeviceMapFirstLoad(false);
        }
      }, 4000);
    }
  }, [deviceViewport]);

  useEffect(() => {
    if (deviceAlertPoints.length > 0 && isDeviceMapLoaded && mapDeviceRef.current) {
      onDeviceFitBounds(deviceAlertPoints);
    }
  }, [deviceAlertPoints, isDeviceMapLoaded]);

  return (userPermissions.length === 0 || !canViewThisPage) ? null : (
    <div className="the-content dashboard">
      <div className="container-fluid content mt-5" >
        <div className="section-usage">
          <hr></hr>
           {/* design 2 */}
          <div className="row mt-4 alert-left-box">
            <div className="col-md-4 pd-r-0 bg-alert-map usage-alert-tab">
              <div className="bg-white recent">
                <div className="heading">
                  <h3 className="title">Usage Insights</h3>
                </div>
                <div className="tickets">
                  {UsageAlerts(totalAlertsData)}
                </div>
              </div>

            </div>
            <div className="col-md-8 pd-l-0">
              <div className="map">
                <div className="map-layer">
                  <div className="heading min-h-heading">
                    <h3 className="title text-right pr-5">
                      {/* <span onClick={() => exportUsageDataMap()}>Export <i className="fas fa-download mr-4" /></span>
                      <span>View all <i className="far fa-angle-right" /></span> */}
                    </h3>
                  </div>
                  <div className="map-active">
                    <ReactMapGL
                          ref={mapRef}
                          {...viewport}
                          mapStyle={normalViewport.mapStyle}
                          mapboxApiAccessToken={mapboxAccessKey}
                          onViewportChange={setViewport}
                          attributionControl={false}
                          onLoad={() => 
                            {setIsMapLoaded(true), console.log('loaded')}}
                          maxZoom={20}
                        >
                          {readyForRenderClusterLayersMaps ? (
                            <>
                              <MapClusters
                              bounds={readyForRenderClusterLayersMaps}
                              data={usageAlertPoints}
                              onMouseIn={setNamePopupInfo}
                              onMouseOut={clearNamePopupInfo}
                              onClick={onMarkerAlertClick}
                              viewport={viewport}
                              setViewport={setViewport}
                              zoom={viewport.zoom}
                              type="customers"
                              visible={true}
                            />
                            </>
                          ) : null}

                          {alertPopupInfo && (
                            <Popup
                              tipSize={5}
                              anchor="bottom"
                              longitude={alertPopupInfo.longitude}
                              latitude={alertPopupInfo.latitude}
                              onClose={setAlertPopupInfo}
                              offsetLeft={0}
                              offsetTop={-10}
                              closeButton={true}
                              closeOnClick={false}
                            >
                              <MarkerInfo info={alertPopupInfo} type="info-window" />
                            </Popup>
                          )}
                          {nameAlertPopupInfo && (
                            <Popup
                              tipSize={5}
                              anchor="bottom"
                              longitude={nameAlertPopupInfo.longitude}
                              latitude={nameAlertPopupInfo.latitude}
                              onClose={setNameAlertPopupInfo}
                              offsetLeft={0}
                              offsetTop={-10}
                              closeButton={false}
                              closeOnClick={true}
                            >
                              <MarkerInfo info={nameAlertPopupInfo} type="tooltip" />
                            </Popup>
                          )}
                    </ReactMapGL>
                  </div>
                </div>
              </div>
            </div>
          </div>

          

          {/* Start design Usage graph */}
          <div className="row mt-4">
            <div className="col-md-6">
              <div className="recent">
                <div className={`heading-h-usage-customer bg-white ${showUsagePicker ? 'no-p' : ''}`}>
                  <h3 className="title">
                    <div className="header-container">
                      <span className="left-text">Average daily usage (kL/d)</span>
                      <span className="right-text dropdown date-range-picker" onClick={() => toggleDropdownUsageCustomer()} > {dayOfUsageCustomer}{showUsagePicker ? ':' : ''} 
                      {showUsagePicker && (
                        <span className="dropdown" onClick={handleChildClick}>
                          <DateRangePicker
                            setting={{
                              startDate: dateRangeWaterUsage[0],
                              endDate: dateRangeWaterUsage[1],
                            }}
                            dateDisplayFormat="MMM DD"
                            onApply={onApplyHightUsageCustomer}
                          >
                            <input type="text" className="form-control input-date-range"/>
                          </DateRangePicker>
                        </span>
                      )}

                      <i className="fas fa-caret-down pl-1" />
                      {isOpenUsageCustomer && (
                          <ul className="dropdown-list">
                            {listDays.map((item, index) => (
                              <li key={index} className="dropdown-item text-center" onClick={() => onChangeHighUsageCustomer(item)} >
                                {item}
                              </li>
                            ))}
                          </ul>
                        )}
                      </span>
                      
                    </div>
                  </h3>
                </div>
                <div className="bg-gray">
                  {highUsageCustomers.map((item, index) => (
                    <Link to={`/customer/${item.customer_id}/usage`} style={linkStyle}>  
                      <div key={index} className="item-customer">
                        <div className="header-container">
                          <span className="left-text">{getCustomerName(item)}</span>
                          <span className="right-text">{formatDataML(Number(item.usage))}</span>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>

            <div className="col-md-6">
               {/* Start top 4 night flow customers */}
                  <div className="recent">
                    <div className={`heading-h-usage-customer bg-white ${showNightFlowPicker ? 'no-p' : ''}`}>
                      <h3 className="title">
                        <div className="header-container">
                          <span className="left-text">Average night flow (L/s)</span>
                          <span className="right-text dropdown date-range-picker" onClick={() => toggleDropdownNightFlow()} > {dayOfNightFlow}{showNightFlowPicker ? ':' : ''} 
                          {showNightFlowPicker && (
                            <span className="dropdown" onClick={handleChildClick}>
                              <DateRangePicker
                                setting={{
                                  startDate: dateRangeNightFlow[0],
                                  endDate: dateRangeNightFlow[1],
                                }}
                                dateDisplayFormat="MMM DD"
                                onApply={onApplyNightFlow}
                              >
                                <input type="text" className="form-control input-date-range"/>
                              </DateRangePicker>
                            </span>
                          )}

                          <i className="fas fa-caret-down pl-1" />
                          {isOpenNightFlow && (
                              <ul className="dropdown-list">
                                {listDays.map((item, index) => (
                                  <li key={index} className="dropdown-item text-center" onClick={() => onChangeNightFlow(item)} >
                                    {item}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </span>
                        </div>
                      </h3>
                    </div>
                    <div className="bg-gray">
                      {top4NightFlowCustomers.map((item, index) => (
                        <Link to={`/customer/${item.customer_id}/usage`} style={linkStyle}>  
                          <div key={index} className="item-customer">
                            <div className="header-container">
                              <span className="left-text">{getCustomerName(item)}</span>
                              <span className="right-text">{formatDataML(Number(item.usage))}</span>
                            </div>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                {/* End top 4 night flow customers */}
            </div>
          </div>
          {/* END design Usage graph */}
        </div>


        <div className="mt-4">
          {/* start line chart 1 */}
          {/* <div className="line-chart">
            <h3 className={`title heading-h-usage-customer bg-white ${showWaterUsagePicker ? 'no-p' : ''}`} >
              <div className="header-container">
                <span className="left-text">Non-Revenue Water by Zone (Data test)</span>
                <span className="right-text dropdown date-range-picker" onClick={() => toggleDropdownWaterUsage()} > {dayOfWaterUsage}{showWaterUsagePicker ? ':' : ''} 
                  {showWaterUsagePicker && (
                    <span className="dropdown" onClick={handleChildWaterUsageClick}>
                      <DateRangePicker
                        setting={{
                          startDate: dateRangeWaterUsage[0],
                          endDate: dateRangeWaterUsage[1],
                        }}
                        dateDisplayFormat="MMM DD"
                        onApply={onApplyWaterUsageChart}
                      >
                        <input type="text" className="form-control input-date-range"/>
                      </DateRangePicker>
                    </span>
                  )}

                  <i className="fas fa-caret-down pl-1" />
                  {isOpenWaterUsage && (
                      <ul className="dropdown-list">
                        {listDays.map((item, index) => (
                          <li key={index} className="dropdown-item text-center" onClick={() => onChangeWaterUsage(item)} >
                            {item}
                          </li>
                        ))}
                      </ul>
                  )}
                </span>
              </div>
            </h3>
              <div className="p-4 bg-gray">
                <CustomLegend />
                <ResponsiveContainer width="100%" height={200}>
                  <LineChart data={dataNonRevenue}>
                    <CartesianGrid vertical={false} stroke="#ccc" strokeDasharray="0"/>
                    <XAxis dataKey="displayDate" />
                    <YAxis
                      yAxisId="left"
                      orientation="left"
                      label={{ value: "kL",  position: "insideLeft" }}
                    />
                    <YAxis
                      yAxisId="right"
                      orientation="right"
                      label={{ value: "%", position: "insideRight" }}
                      domain={[0, 16]}
                    />
                    <Tooltip />
                    <Legend iconType={"circle"} />

                      {meterNames.map((meterName, index) => (
                      <Line key={index + ""} yAxisId="left" type="monotone" dataKey={meterName} stroke={index === 0 ? "#04a2b3" : randomColor(index)} strokeWidth={2} unit={" kL"} dot={false} />
                    ))} 
                    <ReferenceArea
                      yAxisId="right"
                      y1={targetRangeStart}
                      y2={targetRangeEnd}
                      fill="#008a007d"
                      fillOpacity={0.2}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>

          </div> */}
          {/* end line chart 1 */}
        </div>

        <div className="section-devices mt-5">
          {/* <h2 className="mb-0">Devices</h2> */}
          <hr></hr>
          {/* design 3 */}
          <div className="row mt-4 alert-left-box">
            <div className="col-md-4 pr-0 bg-alert-map">
              <div className="bg-white recent">
                <div className="heading">
                  <h3 className="title">Device Alerts</h3>
                </div>
                <div className="tickets">
                  {deviceAlerts.map((item, index) => (
                    <div 
                        key={index}  
                        className={`row cursor mt-3 ${activeDeviceIndex == index ? 'active-item-full-w' : 'alert'}`}
                        onClick={() => handleDeviceClick(index, item.type)}
                      >
                        <div className="col-md-2 ticket-total-alert min-h-alert">
                          <p>{item.type === "poor_signal_strength" ? numberDevicePoorSignal : item.value}</p>
                        </div>
                        <div className={`col-md-10 pr-0 min-h-alert title-alert ${activeDeviceIndex == index ? 'active-d' : ''}`} >
                          {item.title}
                        </div>
                    </div>
                  ))}
                </div>
              </div>

            </div>
            <div className="col-md-8 pl-0">
              <div className="map">
                <div className="map-layer">
                  <div className="heading min-h-heading">
                    <h3 className="title text-right pr-5">
                      {/* <span onClick={() => exportDeviceDataMap()}>Export <i className="fas fa-download mr-4" /></span>
                      <span>View all <i className="far fa-angle-right" /></span> */}
                    </h3>
                  </div>
                  <div className="map-active">
                  <ReactMapGL
                          ref={mapDeviceRef}
                          {...deviceViewport}
                          mapStyle={normalViewport.mapStyle}
                          mapboxApiAccessToken={mapboxAccessKey}
                          onViewportChange={setDeviceViewport}
                          attributionControl={false}
                          onLoad={() => 
                            {setIsDeviceMapLoaded(true), console.log('loaded')}}
                          maxZoom={20}
                        >
                          <>
                            <MapClusters
                              bounds={readyForRenderClusterLayers}
                              data={deviceAlertPoints}
                              onMouseIn={setNamePopupInfo}
                              onMouseOut={clearNamePopupInfo}
                              onClick={onMarkerClick}
                              viewport={deviceViewport}
                              setViewport={setDeviceViewport}
                              zoom={deviceViewport.zoom}
                              type={activeDeviceType}
                              visible={true}
                            />
                          </>

                        {popupInfo && (
                          <Popup
                            tipSize={5}
                            anchor="bottom"
                            longitude={popupInfo.longitude}
                            latitude={popupInfo.latitude}
                            onClose={setPopupInfo}
                            offsetLeft={0}
                            offsetTop={-10}
                            closeButton={true}
                            closeOnClick={false}
                          >
                            <MarkerInfo info={popupInfo} type="info-window" />
                          </Popup>
                        )}
                        {namePopupInfo && (
                          <Popup
                            tipSize={5}
                            anchor="bottom"
                            longitude={namePopupInfo.longitude}
                            latitude={namePopupInfo.latitude}
                            onClose={setNamePopupInfo}
                            offsetLeft={0}
                            offsetTop={-10}
                            closeButton={false}
                            closeOnClick={true}
                          >
                            <MarkerInfo info={namePopupInfo} type="tooltip" />
                          </Popup>
                        )}
                    </ReactMapGL>
                  </div>
                </div>
              </div>
            </div>
          </div>    

        </div>
       
                    
        {/* design 4 */}
        <div className="row mt-5">
          {/* <div className={`header-container ${isActive ? 'active' : ''}`}> */}
          <div className="col-md-4">
            <div className="recent">
              <div className="heading-h-usage-customer bg-white">
                <h3 className="title">
                  <div className="header-container">
                    <span className="left-text">Device Stats</span>
                    {/* <span className="right-text dropdown" onClick={() => toggleDropdownDevice()} >Last {dayOfDevice} days <i className="fas fa-caret-down" />
                      {isOpenDevice && (
                          <ul className="dropdown-list">
                            {listDays.map((item, index) => (
                              <li key={index} className="dropdown-item text-center" onClick={() => onChangeDeviceStats(item)} >
                                {item}
                              </li>
                            ))}
                          </ul>
                        )}
                      </span> */}
                  </div>
                </h3>
              </div>

              <div className="bg-gray">
                <div className="item-customer">
                    <div className="header-container">
                      <span className="left-text">Total Registered Devices</span>
                      <span className="right-text">{totalMeter}</span>
                    </div>
                  </div>
                  <div className="item-customer">
                    <div className="header-container">
                      <span className="left-text">Responsive in last 7 days</span>
                      <span className="right-text">{deliveryPercent ? `${deliveryPercent} %`  : '0'}</span>
                    </div>
                  </div>
                  {/* <div className="item-customer">
                    <div className="header-container">
                      <span className="left-text">Data delivery</span>
                      <span className="right-text">{deliveryPercent[0] ? `${deliveryPercent[0]['Percent']} %`  : '0'}</span>
                    </div>
                  </div> */}
                  <div className="item-customer">
                    <div className="header-container">
                      {/* <span className="left-text">Signal Strength Strong</span>
                      <span className="right-text">{deviceStats.negative88}</span> */}
                      <ContentWithTooltip
                        title="Signal Strength Strong"
                        type="name"
                        content="RSRP >=-88"
                      />
                      <span className="right-text">{deviceStats.negative88}</span>
                    </div>
                  </div>
                 
                  <div className="item-customer">
                    <div className="header-container">
                      {/* <span className="left-text">Signal Strength Moderate</span> */}
                      <ContentWithTooltip
                        title="Signal Strength Moderate"
                        type="name"
                        content="-89 and -111"
                      />
                      <span className="right-text">{deviceStats.negative111}</span>
                    </div>
                  </div>
                  <div className="item-customer">
                    <div className="header-container">
                      {/* <span className="left-text">Signal Strengrh Poor</span> */}
                      <ContentWithTooltip
                        title="Signal Strength Poor"
                        type="name"
                        content="<=-112"
                      />
                      <span className="right-text">{deviceStats.negative112}</span>
                    </div>
                  </div>
              </div>
            </div>
          </div>

          <div className="col-md-8">
            {/* start line chart 1 */}
            <div className="line-chart">
                <h3 className={`title heading-h-usage-customer bg-white ${showPercentMeterPicker ? 'no-p' : ''}`} >
                  <div className="header-container">
                    <span className="left-text">Percentage responsive meters</span>
                    <span className="right-text dropdown date-range-picker" onClick={() => toggleDropdownPercentMeter()} > {dayOfPercentMeter}{showPercentMeterPicker ? ':' : ''} 
                      {showPercentMeterPicker && (
                        <span className="dropdown" onClick={handleChildPercentMeterClick}>
                          <DateRangePicker
                            setting={{
                              startDate: dateRangeWaterUsage[0],
                              endDate: dateRangeWaterUsage[1],
                            }}
                            dateDisplayFormat="MMM DD"
                            onApply={onApplyPercentMeterChart}
                          >
                            <input type="text" className="form-control input-date-range"/>
                          </DateRangePicker>
                        </span>
                      )}

                      <i className="fas fa-caret-down pl-1" />
                      {isOpenPercentMeter && (
                          <ul className="dropdown-list">
                            {listDays.map((item, index) => (
                              <li key={index} className="dropdown-item text-center" onClick={() => onChangePercentMeter(item)} >
                                {item}
                              </li>
                            ))}
                          </ul>
                      )}
                    </span>
                  </div>
                </h3>
                {/* <h3 className="title">
                  <div className="header-container">
                    <span className="left-text">Percentage responsive meters</span>
                    <span className="right-text dropdown" onClick={() => toggleDropdownPercentMeter()} >Last {dayOfPercentMeter} days <i className="fas fa-caret-down" />
                      {isOpenPercentMeter && (
                          <ul className="dropdown-list">
                            {listDays.map((item, index) => (
                              <li key={index} className="dropdown-item text-center" onClick={() => setDayOfPercentMeter(item)} >
                                {item}
                              </li>
                            ))}
                          </ul>
                        )}
                      </span>
                  </div>
                </h3> */}
                {/* <h3 className="title">Percentage responsive meters</h3> */}
              </div>
              <div className="p-4 bg-gray">
                <ResponsiveContainer width="100%" height={225}>
                  <LineChart data={dataPercentMeter}>
                    <CartesianGrid vertical={false} stroke="#ccc" strokeDasharray="0"/>
                    <XAxis dataKey="date" />
                    <YAxis stroke="#e9ebf1" 
                    // label={yLabel} 
                    domain={[0, 100]} 
                    />
                    <Tooltip />
                    {/* <Legend  iconType={"circle"} /> */}
                    <Line type="monotone" dataKey="Percent" stroke="#04a2b3" strokeWidth={2} dot={false}/>
                  </LineChart>
                </ResponsiveContainer>
              </div>
            {/* </div> */}
            {/* end line chart 1 */}

            {/* start line chart 2 */}
            {/* <div className="line-chart mt-4">
              <h3 className={`title heading-h-usage-customer bg-white ${showPercentDeliveryPicker ? 'no-p' : ''}`} >
                <div className="header-container">
                  <span className="left-text">Percentage data delivery</span>
                  <span className="right-text dropdown date-range-picker" onClick={() => toggleDropdownPercentDelivery()} > {dayOfPercentDelivery}{showPercentDeliveryPicker ? ':' : ''} 
                    {showPercentDeliveryPicker && (
                      <span className="dropdown" onClick={handleChildPercentDeliveryClick}>
                        <DateRangePicker
                          setting={{
                            startDate: dateRangeWaterUsage[0],
                            endDate: dateRangeWaterUsage[1],
                          }}
                          dateDisplayFormat="MMM DD"
                          onApply={onApplyPercentDeliveryChart}
                        >
                          <input type="text" className="form-control input-date-range"/>
                        </DateRangePicker>
                      </span>
                    )}

                    <i className="fas fa-caret-down pl-1" />
                    {isOpenPercentDelivery && (
                        <ul className="dropdown-list">
                          {listDays.map((item, index) => (
                            <li key={index} className="dropdown-item text-center" onClick={() => onChangePercentDelivery(item)} >
                              {item}
                            </li>
                          ))}
                        </ul>
                    )}
                  </span>
                </div>
              </h3>
              <div className="p-4 bg-gray">
                <ResponsiveContainer width="100%" height={200}>
                  <LineChart data={dataPercentDelivery}>
                    <CartesianGrid vertical={false} stroke="#ccc" strokeDasharray="0"/>
                    <XAxis dataKey="date" />
                    <YAxis stroke="#e9ebf1" 
                    domain={[0, 100]}
                    />
                    <Tooltip />
                    <Line type="monotone" dataKey="Percent" stroke="#04a2b3" strokeWidth={2} dot={false}/>
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div> */}
            {/* end line chart 2 */}
          </div>

        </div>
      </div>
    </div>
  );

};

export default DashBoardPage;
