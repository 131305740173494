import React from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

const ContentWithTooltip = ({
  title,
  type,
  content,
}) => {
  function renderTooltip(tooltipType, content) {
    return (
      <Tooltip id={`tooltip-${tooltipType}-${title}`}>
        {
          content
        }
      </Tooltip>
    );
  }

  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip(type, content)}
    >
      <span>{title}</span>
    </OverlayTrigger>
  )
}

export default ContentWithTooltip
